import { useCallback } from 'react';
import { useDrawer } from '../../../core';
import { MerchantForm } from '../components/MerchantForm';
import { useUpdateMerchantMutation } from '../store/merchant.api';
import { FormUtils } from '../utils/FormUtils';

export const UpdateMerchant: React.FC = () => {
  const [updateMerchant, { isSuccess }] = useUpdateMerchantMutation();
  const { resource } = useDrawer();

  const onFinish = useCallback(
    (values: Record<string, any>) => {
      const {
        id,
        end_date,
        logo,
        cover,
        featured_start_date,
        featured_end_date,
        ...rest
      } = values;

      updateMerchant({
        id: resource.id,
        end_date: end_date?.format('YYYY-MM-DD'),
        ...rest,
        ...(featured_start_date && {
          featured_start_date: featured_start_date.format('YYYY-MM-DD'),
        }),
        ...(featured_end_date && {
          featured_end_date: featured_end_date.format('YYYY-MM-DD'),
        }),
        ...(logo && { logo: FormUtils().getFileName(logo) }),
        ...(cover && { cover: FormUtils().getFileName(cover) }),
      });
    },
    [resource.id, updateMerchant]
  );

  return <MerchantForm onFinish={onFinish} isSubmitted={isSuccess} />;
};
