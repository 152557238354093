import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDrawer } from '../../../../../core';
import { FormUtils } from '../../../utils/FormUtils';
import { OfferForm } from '../components/OfferForm';
import { useUpdateOfferMutation } from '../store/offer.api';

export const UpdateOffer: React.FC = () => {
  const { merchantId } = useParams<string>();
  const [updateOffer, { isSuccess }] = useUpdateOfferMutation();
  const { resource } = useDrawer();

  const onFinish = useCallback(
    (values: any) => {
      const {
        branches,
        cover,
        offer_type,
        offer_value,
        max_amount,
        min_cart_amount,
        featured_start_date,
        featured_end_date,
        flash_deal_start_date,
        flash_deal_end_date,
        promo_code,
        promo_link,
        limited_redeems,
        limited_redeems_type,
        limited_redeems_count,
        start_date,
        end_date,
        ...rest
      } = values;

      updateOffer({
        ...rest,
        promo_code: promo_code || undefined,
        promo_link: promo_link || undefined,
        branches,
        merchantId,
        id: resource.id,
        cover: FormUtils().getFileName(cover),
        featured_start_date: featured_start_date?.format('YYYY-MM-DD') || null,
        featured_end_date: featured_end_date?.format('YYYY-MM-DD') || null,
        flash_deal_start_date:
          flash_deal_start_date?.format('YYYY-MM-DD') || null,
        flash_deal_end_date: flash_deal_end_date?.format('YYYY-MM-DD') || null,
        start_date: start_date?.format('YYYY-MM-DD'),
        end_date: end_date?.format('YYYY-MM-DD'),
        components: [
          {
            type: offer_type,
            value: offer_value,
            max_amount: !!max_amount ? max_amount : null,
            min_cart_amount: !!min_cart_amount ? min_cart_amount : null,
            limited_redeems_type: !!limited_redeems_type
              ? limited_redeems_type
              : null,
            limited_redeems_count: !!limited_redeems_count
              ? limited_redeems_count
              : null,
            limited_redeems,
          },
        ],
      });
    },
    [merchantId, resource.id, updateOffer]
  );

  return <OfferForm isSubmitted={isSuccess} onFinish={onFinish} />;
};
