import { useSearchParams } from 'react-router-dom';
import {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_PAGE_NUMBER,
} from '../../common/constants';

export const useAppParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || DEFAULT_PAGE_NUMBER;
  const limit = searchParams.get('limit') || DEFAULT_PAGE_LIMIT;
  const search = searchParams.get('search') || undefined;

  return {
    search,
    page: Number(page),
    limit: Number(limit),
    setParams: setSearchParams,
    getParams: searchParams.get,
  };
};
