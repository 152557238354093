import { Tag } from 'antd';
import { MerchantTypeEnum } from '../enums';

type TProps = {
  merchantType: string;
};

export const MerchantType: React.FC<TProps> = ({ merchantType }) => {
  let type = merchantType;
  let color = 'green';

  switch (merchantType) {
    case MerchantTypeEnum.IN_STORE:
      type = 'In Store';
      color = 'blue';
      break;
    case MerchantTypeEnum.ONLINE:
      type = 'Online';
      color = 'green';
      break;
    case MerchantTypeEnum.SOCIAL_MEDIA:
      type = 'Social Media';
      color = 'orange';
      break;
  }

  return <Tag color={color}>{type}</Tag>;
};
