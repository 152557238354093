import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { Reports } from './views/list';

export const reportRoutes: IAppRoute[] = [
  {
    path: '/reports',
    component: Reports,
    roles: [...Object.values(UserRoleEnum)],
  },
];
