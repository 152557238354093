import { UploadFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { ISO8601_DATE_FORMAT } from '../../../../../common';
import { TFileInfo } from '../../../../../common/types';

import {
  setMerchants,
  setTaskId,
  useAppDispatch,
  useImportWizard,
} from '../../../../../core';

import { FileUtils } from '../../../utils/FileUtils';
import { useCreateTaskMutation } from '../store/import.api';
import { MerchantsImportTable } from './MerchantsImportTable';
import { UploadButton } from './UploadButton';

export const MerchantStep: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>();

  const [createTask, { data: task, isSuccess, error, reset }] =
    useCreateTaskMutation();

  const { taskId } = useImportWizard();
  const dispatch = useAppDispatch();

  const onFileUploaded = useCallback((info: TFileInfo) => {
    if (FileUtils().isFileUploaded(info)) {
      return;
    }

    setFileList(info.fileList);
  }, []);

  useEffect(() => {
    if (!fileList || fileList.length === 0) {
      return;
    }

    const filename = fileList[0].response.filename;

    createTask({ filename });
  }, [createTask, fileList]);

  useEffect(() => {
    if (!isSuccess || error !== undefined || !task?.id || !fileList) {
      return;
    }

    const file = fileList[0].originFileObj as File;

    FileUtils().readExcelFile(file, (data) => {
      dispatch(
        setMerchants(
          data.map((merchant: any) => ({
            name: merchant.name,
            about: merchant.about,
            status: merchant.status,
            is_featured: merchant.is_featured,
            type: merchant.type?.split('|'),
            end_date: dayjs(merchant.end_date).format(ISO8601_DATE_FORMAT),
            categories: merchant.categories?.split('|'),
            key: `${merchant.name}-${merchant.status}-${merchant.type}`,
          }))
        )
      );
    });

    dispatch(setTaskId(task.id));
    reset();
  }, [isSuccess, error, dispatch, task?.id, reset, fileList]);

  return (
    <>
      {taskId ? (
        <MerchantsImportTable taskId={taskId} />
      ) : (
        <UploadButton
          onFileUploaded={onFileUploaded}
          label="Select Merchants File"
        />
      )}
    </>
  );
};
