import { Table, TableProps, Tag } from 'antd';

import { useImportWizard } from '../../../../../core';
import { MerchantType } from '../../../components/MerchantType';

type TProps = {
  taskId: string;
};

export const MerchantsImportTable: React.FC<TProps> = ({ taskId }) => {
  const { merchants } = useImportWizard();

  const columns: TableProps['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'About',
      dataIndex: 'about',
      ellipsis: true,
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
    },
    {
      title: 'Featured',
      dataIndex: 'is_featured',
      render: (isFeatured) => (
        <Tag color={isFeatured ? 'green' : 'red'}>
          {isFeatured ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (types: Array<string>) =>
        types.map((type) => <MerchantType key={type} merchantType={type} />),
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      width: 300,
      render: (categories: Array<string>) =>
        categories.map((category) => <Tag>{category}</Tag>),
    },
  ];

  return (
    <Table columns={columns} pagination={false} dataSource={merchants || []} />
  );
};
