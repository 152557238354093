import { superAppApi } from '../../../core';

const subscriptionsApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    subscribe: build.mutation<
      void,
      {
        userId: string;
        package_id: string;
        expires_at: string;
        admin_notes: string;
      }
    >({
      query: ({ userId, ...rest }) => ({
        url: `/users/${userId}/subscribe`,
        method: 'POST',
        body: {
          data: {
            attributes: rest,
          },
        },
      }),
      invalidatesTags: ['Customers', 'Staff'],
    }),
    unsubscribe: build.mutation<
      void,
      {
        userId: string;
        admin_notes: string;
      }
    >({
      query: ({ userId, ...rest }) => ({
        url: `/users/${userId}/unsubscribe`,
        method: 'POST',
        body: {
          data: {
            attributes: rest,
          },
        },
      }),
      invalidatesTags: ['Customers', 'Staff'],
    }),
  }),
});

export const { useSubscribeMutation, useUnsubscribeMutation } =
  subscriptionsApi;
