import { IAppRoute } from '../../common/interfaces';
import { Redeem } from './views/redeem';

export const redeemRoutes: IAppRoute[] = [
  {
    path: '/redeem',
    roles: [],
    component: Redeem,
  },
];
