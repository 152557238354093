import { App, Button, Steps, theme } from 'antd';
import { omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  resetWizard,
  useAppDispatch,
  useImportValidation,
  useImportWizard,
} from '../../../../../core';
import { IMerchantAttributes } from '../../../types';
import { IMerchantBranchAttributes } from '../../branches/types';
import { IMerchantLinkAttributes } from '../../links/types';
import { BranchStep } from '../components/BranchStep';
import { LinkStep } from '../components/LinkStep';
import { MerchantStep } from '../components/MerchantStep';
import { useExecuteTaskMutation } from '../store/import.api';

export const ImportWizard: React.FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { taskId, merchants, branches, links } = useImportWizard();
  const { message, notification } = App.useApp();
  const { validation } = useImportValidation();
  const [executeTask, { isLoading, isSuccess, error }] =
    useExecuteTaskMutation<{
      isLoading: boolean;
      isSuccess: boolean;
      error: { data: { message: string } };
    }>();

  const steps = [
    {
      title: 'Merchants',
      content: <MerchantStep />,
    },
    {
      title: 'Branches',
      content: <BranchStep />,
    },
    {
      title: 'Links',
      content: <LinkStep />,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const contentStyle: React.CSSProperties = {
    lineHeight: '300px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    paddingBottom: 1,
    paddingLeft: 3,
    paddingRight: 3,
  };

  const onImport = useCallback(() => {
    if (!taskId || !merchants || !branches || !links) {
      message.error('Importing failed!, please add all required assets.');
      return;
    }

    console.log('validation', validation);
    if (!validation) {
      message.error('Oops! please select all required fields.');
      return;
    }

    executeTask({
      taskId,
      data: {
        merchants: merchants.map((merchant: IMerchantAttributes) =>
          omit(merchant, ['key'])
        ),
        branches: branches.map((branch: IMerchantBranchAttributes) =>
          omit(branch, ['key'])
        ),
        links: links.map((link: IMerchantLinkAttributes) =>
          omit(link, ['key'])
        ),
      },
    });
  }, [branches, executeTask, links, merchants, message, taskId, validation]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    message.success('Importing complete!');

    setTimeout(() => navigate('/merchants'), 2000);
  }, [isSuccess, message, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetWizard());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!error) {
      return;
    }

    notification.error({
      message: 'Importing failed!',
      description: error.data.message,
    });
  }, [error, notification]);

  return (
    <>
      <Steps
        current={current}
        items={steps.map((step) => ({ key: step.title, title: step.title }))}
      />
      <div style={contentStyle}>{steps[current].content}</div>
      <div style={{ marginTop: 24 }}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} disabled={!taskId}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && taskId && (
          <Button loading={isLoading} type="primary" onClick={onImport}>
            Import
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </>
  );
};
