import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { superAppApi } from '../api';
import authReducer from './auth.slice';
import drawerReducer from './drawer.slice';
import wizardReducer from './wizard.slice';

export const offersStore = configureStore({
  reducer: {
    superAppDrawer: drawerReducer,
    superAppAuth: authReducer,
    superAppImportWizard: wizardReducer,
    [superAppApi.reducerPath]: superAppApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(superAppApi.middleware),
});

setupListeners(offersStore.dispatch);

export type AppDispatch = typeof offersStore.dispatch;
export type RootState = ReturnType<typeof offersStore.getState>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
