export const FormUtils = () => ({
  normValue: (value: string) => (value === '' ? undefined : value),
  normFile: (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  },
  getFileName: (
    fileList: Array<{ response?: { filename: string } }>
  ): string | undefined => {
    return fileList[0]?.response?.filename || undefined;
  },
});
