import { Col, Row, Spin } from 'antd';
import { RahetBallyLogo } from '../../layout/components/RahetBallyLogo';

export const Loading: React.FC = () => {
  return (
    <Row
      justify="center"
      align="middle"
      style={{ minHeight: '100vh', backgroundColor: '#db2877' }}
    >
      <Col span={3}>
        <RahetBallyLogo />
        <Spin size="large" />
      </Col>
    </Row>
  );
};
