import { App, Card, Col, Row, Space, Typography } from 'antd';
import Lottie from 'lottie-react';
import { useEffect } from 'react';
import notFoundAnimation from '../../../assets/animations/m5pbLCxUGP.json';
import { useAuthUser } from '../../../core';
import {
  useRedeemOfferMutation,
  useValidateRedeemMutation,
} from '../store/redeem.api';

const { Text } = Typography;
const { Meta } = Card;

type TProps = {
  redeem_code: string;
  cart_total: number;
  success: () => void;
};

export const RedeemDetails: React.FC<TProps> = ({
  redeem_code,
  cart_total,
  success,
}) => {
  const { user } = useAuthUser();
  const [validateRedeem, { data, error, isLoading }] =
    useValidateRedeemMutation();
  const { modal } = App.useApp();
  const [redeemOffer, { isSuccess }] = useRedeemOfferMutation();

  useEffect(() => {
    validateRedeem({
      merchantId: user?.meta?.merchant?.id,
      redeem_code,
      cart_total,
    });
  }, [cart_total, redeem_code, user?.meta?.merchant?.id, validateRedeem]);

  useEffect(() => {
    if (isSuccess) {
      success();
    }
  }, [isSuccess, success]);

  return (
    <Row justify="center">
      <Col>
        {error ? (
          <Space direction="vertical" align="center">
            <Text type="danger" style={{ fontSize: 18 }}>
              {(error as Record<string, any>)?.data?.message}
            </Text>
            <Lottie style={{ width: 300 }} animationData={notFoundAnimation} />
          </Space>
        ) : (
          <Card
            hoverable
            style={{ width: 300 }}
            loading={isLoading}
            cover={
              data?.offer.attributes.cover && (
                <img alt="Offer Cover" src={data?.offer.attributes.cover} />
              )
            }
            onClick={() => {
              modal.confirm({
                title: 'Redeem Offer',
                content: 'Are you sure you want to redeem this offer?',
                okType: 'primary',
                onOk: () => {
                  redeemOffer({
                    redeem_code,
                    cart_total,
                  });
                },
              });
            }}
          >
            <Space direction="vertical" size={16}>
              <Meta
                title={'Offer:'}
                description={data?.offer.attributes.name}
              />
              <Meta
                title={'Customer:'}
                description={data?.customer.full_name}
              />
            </Space>
          </Card>
        )}
      </Col>
    </Row>
  );
};
