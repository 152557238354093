import { IAppRoute } from '../../../../common/interfaces';
import { UserRoleEnum } from '../../../users/enums';
import { ImportWizard } from './views/import';

export const importRoutes: IAppRoute[] = [
  {
    path: '/merchants/import',
    component: ImportWizard,
    roles: [UserRoleEnum.ADMIN],
  },
  {
    // TODO: not used yet
    path: '/merchants/import/:taskId',
    component: ImportWizard,
    roles: [UserRoleEnum.ADMIN],
  },
];
