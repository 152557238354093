import { Button, Upload, theme } from 'antd';
import { useState } from 'react';
import {
  API_UPLOAD_BASEURL,
  EXCEL_ACCEPTED_MIME_TYPES,
} from '../../../../../common/constants';
import { TFileInfo } from '../../../../../common/types';
import { useAuthUser } from '../../../../../core';

type TProps = {
  label: string;
  onFileUploaded: (info: TFileInfo) => void;
};

export const UploadButton: React.FC<TProps> = ({ onFileUploaded, label }) => {
  const { token } = theme.useToken();
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  const [uploading, setUploading] = useState<boolean>(false);

  return (
    <Upload
      accept={EXCEL_ACCEPTED_MIME_TYPES}
      maxCount={1}
      action={API_UPLOAD_BASEURL}
      onChange={(info) => {
        setUploading(true);
        onFileUploaded(info);
      }}
      onPreview={() => {
        setUploading(false);
      }}
      showUploadList={false}
      headers={{
        Authorization: `Bearer ${accessToken}`,
      }}
      disabled={uploading}
    >
      <Button
        loading={uploading}
        style={{ border: `1px dashed ${token.colorBorder}` }}
      >
        {label}
      </Button>
    </Upload>
  );
};
