import { superAppApi } from '../../../core';
import { INotificationParams } from '../types';

const notificationApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    sendNotification: build.mutation<void, INotificationParams>({
      query: ({ title, body, token, topic, data }) => ({
        url: '/firebase/notification',
        method: 'POST',
        body: {
          data: {
            attributes: {
              title,
              body,
              token,
              topic,
              data,
            },
          },
        },
      }),
    }),
  }),
});

export const { useSendNotificationMutation } = notificationApi;
