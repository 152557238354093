import { TPaginationParams, TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';

import { IStaff } from '../types';

const staffApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getStaffList: build.query<IStaff[], TPaginationParams>({
      query: ({ page, limit }) => ({
        url: '/staff',
        method: 'GET',
        params: { page, limit },
      }),
      transformResponse: (response: TResponse<IStaff[]>) => response.data,
      providesTags: ['Staff'],
    }),
  }),
});

export const { useGetStaffListQuery } = staffApi;
