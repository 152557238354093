import { App } from 'antd';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { closeDrawer, useAppDispatch, useDrawer } from '../../../../../core';
import { BranchForm } from '../components/BranchForm';
import { useUpdateBranchMutation } from '../store/branch.api';
import { IMerchantBranchAttributes } from '../types';

type TBranchFormProps = {
  values?: IMerchantBranchAttributes;
};

export const UpdateBranch: React.FC<TBranchFormProps> = ({ values }) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const [
    updateBranch,
    { isSuccess: updateIsSuccess, reset: updateReset, error: updateErrors },
  ] = useUpdateBranchMutation();
  const dispatch = useAppDispatch();
  const { notification } = App.useApp();
  const { resource } = useDrawer();

  const onFinish = useCallback(
    (attributes: any) => {
      updateBranch({
        ...attributes,
        id: resource?.id,
        merchantId,
      });
    },
    [updateBranch, merchantId, resource]
  );

  useEffect(() => {
    if (!updateIsSuccess) {
      return;
    }

    dispatch(closeDrawer());
    updateReset();
  }, [dispatch, updateIsSuccess, updateReset]);

  useEffect(() => {
    if (!updateErrors) {
      return;
    }

    notification.error({
      message: 'Error',
      description: 'Please validate the form and try again.',
    });
  }, [notification, updateErrors]);

  return <BranchForm onFinish={onFinish} />;
};
