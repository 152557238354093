import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { StaffList } from './views/list';

export const staffRoutes: IAppRoute[] = [
  {
    path: '/staff',
    component: StaffList,
    roles: [UserRoleEnum.ADMIN],
  },
];
