import { ExportOutlined } from '@ant-design/icons';
import {
  App,
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import { includes, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { AiOutlineRobot } from 'react-icons/ai';
import { BsToggles } from 'react-icons/bs';
import { GrUserManager } from 'react-icons/gr';
import { MdOutlinePhoneIphone } from 'react-icons/md';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { API_BASEURL } from '../../../common/constants';
import { TActionList } from '../../../common/types';

import {
  ActionListButton,
  AppDrawer,
  DataTable,
  useAppParams,
  useAuthUser,
  useDrawer,
} from '../../../core';
import { useGetPackagesQuery } from '../../subscriptions/store/package.api';
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from '../../subscriptions/store/subscription.api';
import { useUpdateUserMutation } from '../../users/store/user.api';
import { SubscriptionBadge } from '../components/SubscriptionBadge';
import { useGetCustomersQuery } from '../store/customers.api';
import { ICustomerAttributes } from '../types';
import { CreateCustomer } from './create';
import { UpdateCustomer } from './update';

const { Search } = Input;
const { Link } = Typography;

export type TCustomerDrawer = 'CustomerDrawer';

export const CustomerList: React.FC = () => {
  const { page, limit, search, setParams } = useAppParams();
  const navigate = useNavigate();
  const { resource } = useDrawer();
  const { modal, message } = App.useApp();
  const [updateCustomerUser] = useUpdateUserMutation();
  const [subscribeForm] = Form.useForm();
  const [unsubscribeForm] = Form.useForm();
  const { data: packages } = useGetPackagesQuery();
  const [subscribeVisible, setSubscribeVisible] = useState(false);
  const [unsubscribeVisible, setUnsubscribeVisible] = useState(false);
  const [user, setUser] = useState<ICustomerAttributes['user']>();
  const [subscribe, { isLoading: isLoadingSubscribe }] = useSubscribeMutation();
  const [unsubscribe, { isLoading: isLoadingUnsubscribe }] =
    useUnsubscribeMutation();
  const { auth: { access_token: accessToken } = {} } = useAuthUser();

  const { data: customers, isFetching } = useGetCustomersQuery({
    page,
    limit,
    search,
  });

  const onSubscribeClose = useCallback(() => {
    setSubscribeVisible(false);
    setUnsubscribeVisible(false);
    subscribeForm.resetFields();
    unsubscribeForm.resetFields();
  }, [subscribeForm, unsubscribeForm]);

  const onSubscribeFinish = useCallback(
    (values: any) => {
      if (!user) {
        message.error('User not found!');
        return;
      }

      subscribe({
        userId: user.id,
        package_id: values.package_id,
        expires_at: values.expires_at,
        admin_notes: values.admin_notes,
      }).then(() => {
        onSubscribeClose();
      });
    },
    [message, onSubscribeClose, subscribe, user]
  );

  const onUnsubscribeFinish = useCallback(
    (values: any) => {
      if (!user) {
        message.error('User not found!');
        return;
      }

      unsubscribe({
        userId: user.id,
        admin_notes: values.admin_notes,
      }).then(() => {
        onSubscribeClose();
      });
    },
    [message, onSubscribeClose, unsubscribe, user]
  );

  const actions = (record: ICustomerAttributes): TActionList['items'] => {
    return [
      {
        label: 'Manage',
        key: 'manage',
        icon: <GrUserManager />,
        action: (record) => {
          navigate(`/customers/${record.key}`);
        },
      },
      {
        label: 'Start Subscribe',
        key: 'subscribe',
        icon: <AiOutlineRobot color="#00b96b" />,
        action: (record: ICustomerAttributes) => {
          setUser(record.user);
          setSubscribeVisible(true);
        },
        disabled: record.user.subscription !== undefined,
      },
      {
        label: 'End Subscribe',
        key: 'end_subscribe',
        icon: <AiOutlineRobot color="#be3b07" />,
        action: (record: ICustomerAttributes) => {
          setUser(record.user);
          setUnsubscribeVisible(true);
        },
        disabled: record.user.subscription === undefined,
      },
      {
        label: 'Toggle Status',
        key: 'toggle_status',
        icon: <BsToggles />,
        action: (record) => {
          modal.confirm({
            title: 'Toggle customer status',
            content: 'Are you sure you want to toggle status?',
            okType: 'primary',
            onOk: () => {
              updateCustomerUser({
                id: record.user.id,
                status: !record.user.status,
              });
            },
          });
        },
      },
      {
        label: 'Delete',
        key: 'delete',
        icon: <RiDeleteBin5Fill />,
        disabled: true,
        action: (record) => {},
      },
    ];
  };

  const columns: TableProps<ICustomerAttributes>['columns'] = [
    {
      title: 'Full Name',
      render: (record) => record.user.full_name,
      sorter: (a, b) => a.user.full_name.localeCompare(b.user.full_name),
    },
    {
      title: 'Phone',
      render: (record) => (
        <>
          <MdOutlinePhoneIphone size={15} /> {record.user.phone}
        </>
      ),
    },
    {
      title: 'Has Wallet',
      dataIndex: 'has_wallet',
      render: (has_wallet) =>
        has_wallet ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
    },
    {
      title: 'Status',
      render: (record) => (
        <Tag color={record.user.status ? 'green' : 'red'}>
          {record.user.status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Subscription',
      render: (record) =>
        SubscriptionBadge({ subscription: record.user.subscription }),
    },
    {
      title: 'Actions',
      render: (_, record: ICustomerAttributes) => (
        <ActionListButton items={actions(record)} record={record} />
      ),
      width: 110,
      align: 'center',
    },
  ];

  const onSearch = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        setParams({
          page: '1',
          limit: limit.toString(),
        });
        return;
      }

      if (value.length < 2) {
        message.error('Please enter at least 2 characters');
        return;
      }

      setParams({
        search: value,
        page: '1',
        limit: limit.toString(),
      });
    },
    [limit, message, setParams]
  );

  return (
    <>
      <Modal
        title={`Start Subscribe for ${user?.full_name}`}
        width={450}
        open={subscribeVisible}
        onOk={() => subscribeForm.submit()}
        onCancel={onSubscribeClose}
        confirmLoading={isLoadingSubscribe}
      >
        <Form
          form={subscribeForm}
          layout="vertical"
          onFinish={onSubscribeFinish}
        >
          <Form.Item
            name="package_id"
            label="Package"
            required
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Subscription Package"
              options={packages
                ?.filter(
                  (item) =>
                    !includes(
                      ['Lite', 'Plus - orange 70%'],
                      item.attributes.name
                    )
                )
                .map((item) => ({
                  value: item.id,
                  label: item.attributes.name,
                }))}
            />
          </Form.Item>
          <Form.Item
            name="expires_at"
            label="Expire Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="admin_notes"
            label="Admin Notes"
            required
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder="Write a descriptive message to explain why this subscription created manually." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`End Subscription for ${user?.full_name}`}
        width={450}
        open={unsubscribeVisible}
        onOk={() => unsubscribeForm.submit()}
        onCancel={onSubscribeClose}
        confirmLoading={isLoadingUnsubscribe}
      >
        <Form
          form={unsubscribeForm}
          layout="vertical"
          onFinish={onUnsubscribeFinish}
        >
          <Form.Item
            name="admin_notes"
            label="Admin Notes"
            required
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder="Write a descriptive message to explain why this subscription ended manually." />
          </Form.Item>
        </Form>
      </Modal>
      <Space direction="vertical">
        <AppDrawer title="Add New Customer" drawerId="CustomerDrawer">
          {resource ? <UpdateCustomer /> : <CreateCustomer />}
        </AppDrawer>
        <Flex justify="end" gap={16}>
          <Search
            style={{ width: 300 }}
            placeholder="Search customers"
            onSearch={onSearch}
          />
          <Space>
            <Link
              href={`${API_BASEURL}/customers/export?token=${accessToken}`}
              target="_blank"
            >
              <Button type="primary">
                <ExportOutlined /> Export Customers
              </Button>
            </Link>
            <Link
              href={`${API_BASEURL}/users/export-cib?token=${accessToken}`}
              target="_blank"
            >
              <Button type="primary">
                <ExportOutlined /> Export CIB users
              </Button>
            </Link>
          </Space>
        </Flex>
        <DataTable
          data={customers?.data}
          columns={columns}
          total={customers?.meta.totalItems}
          isLoading={isFetching}
          rowSelection={{
            onSelect: (record, selected) => {},
            onSelectAll: (selected, selectedRows, changeRows) => {},
          }}
        />
      </Space>
    </>
  );
};
