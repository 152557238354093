import { App as AntApp } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Login from './auth/views/login';
import { AppMain, useAuthUser } from './core';

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const { isLoggedIn, auth } = useAuthUser();

  if (!isLoggedIn && !auth) {
    return <Login />;
  }

  return (
    <AntApp
      message={{ maxCount: 1 }}
      notification={{ placement: 'bottomLeft' }}
    >
      <AppMain />
    </AntApp>
  );
}

export default App;
