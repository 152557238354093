export enum RedeemStatusEnum {
  CREATED = 'created',
  INITIATED = 'initiated',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum OfferComponentTypeEnum {
  FIXED_AMOUNT = 'fixed_amount',
  FIXED_PERCENTAGE = 'fixed_percentage',
  CASHBACK_FIXED_PERCENTAGE = 'cashback_fixed_percentage',
  BOGO = 'bogo',
  SPECIAL = 'special',
}

export enum OfferTypeEnum {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}
