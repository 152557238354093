import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Flex, Space, TableProps, Tag } from 'antd';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { TActionList } from '../../../common/types';

import {
  ActionListButton,
  AppDrawer,
  DataTable,
  drawerResource,
  openDrawer,
  useAppDispatch,
  useDrawer,
} from '../../../core';
import { useGetBannersQuery } from '../store/banner.api';
import { IBannerAttributes } from '../types';
import { CreateBanner } from './create';
import { EditBanner } from './edit';

export type TBannerDrawer = 'BannerDrawer';

export const BannerList: React.FC = () => {
  const { data: banners, isLoading } = useGetBannersQuery();
  const { resource } = useDrawer();
  const dispatch = useAppDispatch();

  const actions = (record: IBannerAttributes): TActionList['items'] => {
    return [
      {
        label: 'Edit',
        key: 'edit',
        icon: <FaRegEdit />,
        action: (record) => {
          const { key: id, ...reset } = record;

          dispatch(
            drawerResource({
              id,
              type: 'Banner',
              attributes: reset,
            })
          );

          dispatch(openDrawer('BannerDrawer'));
        },
      },
      {
        label: 'Delete',
        key: 'delete',
        icon: <RiDeleteBin5Fill />,
        disabled: true,
        action: (record) => {},
      },
    ];
  };

  const columns: TableProps['columns'] = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Order',
      dataIndex: 'order',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: boolean) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      render: (merchant: { name: string }) => merchant.name,
    },
    {
      title: 'Actions',
      render: (_, record: IBannerAttributes) => (
        <ActionListButton items={actions(record)} record={record} />
      ),
      width: 110,
      align: 'center',
    },
  ];

  return (
    <>
      <AppDrawer
        title={resource ? 'Edit Banner' : 'Add New Banner'}
        drawerId="BannerDrawer"
      >
        {resource ? <EditBanner /> : <CreateBanner />}
      </AppDrawer>
      <Space direction="vertical">
        <Flex justify="end" gap={16}>
          <Space.Compact>
            <Button
              type="primary"
              onClick={() => dispatch(openDrawer('BannerDrawer'))}
            >
              <PlusSquareOutlined /> Add Banner
            </Button>
          </Space.Compact>
        </Flex>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          data={banners ?? []}
        />
      </Space>
    </>
  );
};
