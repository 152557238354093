import { Tag } from "antd";
import { StaffDepartmentEnum } from "../enums";

type TProps = {
    department: string;
}

export const StaffDepartment: React.FC<TProps> = ({department}) => {
    let color = 'geekblue';

    switch (department) {
        case StaffDepartmentEnum.ADMIN:
            color = 'geekblue';
            break;
        case StaffDepartmentEnum.OPERATIONS:
            color = 'cyan';
            break;
        case StaffDepartmentEnum.HR:
            color = 'green';
            break;
        case StaffDepartmentEnum.TECH:
            color = 'purple';
            break;
        case StaffDepartmentEnum.MARKETING:
            color = 'volcano';
            break;
        case StaffDepartmentEnum.FINANCE:
            color = 'orange';
            break;
    }

    return <Tag color={color}>{department.charAt(0).toUpperCase() + department.slice(1)}</Tag>
}