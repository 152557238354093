import { TResponse, UUID } from '../../../../../common/types';
import { superAppApi } from '../../../../../core';
import { IOffer, IOfferAttributes, TCreateOfferArgs } from '../types';

const offersApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getOffers: build.query<IOffer[], { merchantId: UUID }>({
      query: ({ merchantId }) => ({
        url: `/merchants/${merchantId}/offers`,
      }),
      transformResponse: (response: TResponse<IOffer[]>) => response.data,
      providesTags: ['Offers'],
    }),
    createOffer: build.mutation<
      void,
      TCreateOfferArgs & { merchantId: string }
    >({
      query: ({ merchantId, ...data }) => ({
        url: `/merchants/${merchantId}/offers`,
        method: 'POST',
        body: { data },
      }),
      invalidatesTags: ['Offers'],
    }),
    updateOffer: build.mutation<
      void,
      Partial<IOfferAttributes & { id: UUID; merchantId: string }>
    >({
      query: ({ id, merchantId, ...attributes }) => ({
        url: `/merchants/${merchantId}/offers/${id}`,
        method: 'PATCH',
        body: {
          data: {
            id,
            type: 'Offer',
            attributes,
          },
        },
      }),
      invalidatesTags: ['Offers'],
    }),
  }),
});

export const {
  useGetOffersQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
} = offersApi;
