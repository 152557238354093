import { Card, Col, Progress, Row, Space, Typography } from 'antd';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOccasionStatisticsQuery } from '../store/occasion.api';

const { Text } = Typography;

export const EventManage: React.FC = () => {
  const { eventId = '' } = useParams();

  const { data, isFetching, refetch } = useGetOccasionStatisticsQuery({
    id: eventId,
  });

  const [soldPercentage, checkedInPercentage] = useMemo(() => {
    const ticketsSold = data?.tickets_sold || 0;
    const ticketsCheckedIn = data?.checked_in || 0;
    const targetTickets = data?.target_tickets || 1;
    const remaining = data?.remaining || 0;

    return [
      parseFloat(((ticketsSold / targetTickets) * 100).toFixed(2)),
      parseFloat(((ticketsCheckedIn / remaining) * 100).toFixed(2)),
    ];
  }, [data?.tickets_sold, data?.target_tickets]);

  const cardStyle: CSSProperties = {
    textAlign: 'center',
    width: '100%',
  };

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refetch();
    }, 5000);

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <Row align={'middle'} gutter={16}>
      <Col xs={24} lg={12}>
        <Row gutter={16}>
          <Col span={12}>
            <Card style={cardStyle} hoverable>
              <Space direction="vertical" size={16}>
                <Text strong>TICKETS SOLD</Text>
                <Text strong>{data?.tickets_sold}</Text>
                <Progress
                  type="circle"
                  percent={soldPercentage}
                  strokeColor={'#db2877'}
                />
                <Text strong>Target - {data?.target_tickets}</Text>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card style={cardStyle} hoverable>
              <Space direction="vertical" size={16}>
                <Text strong>CHECKED IN</Text>
                <Text strong>{data?.checked_in}</Text>
                <Progress
                  type="circle"
                  percent={checkedInPercentage}
                  strokeColor={'#35BD38'}
                />
                <Text strong>Remaining - {data?.remaining}</Text>
              </Space>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={12}>
        {/* <CheckInChart checkinData={[]} /> */}
      </Col>
    </Row>
  );
};
