import { Space, Spin, Typography } from 'antd';
import { CurrencyUtils } from '../../utils/currency';

const { Text, Title } = Typography;

export const AppStatistic: React.FC<{
  title: string;
  isLoading?: boolean;
  value?: number;
}> = ({ title, isLoading, value }) => {
  const currencyUtils = CurrencyUtils();

  return (
    <Space direction="vertical" size={16}>
      <Text type="secondary">{title}</Text>
      <Title level={4} style={{ marginTop: 0 }}>
        {isLoading ? (
          <Spin />
        ) : (
          currencyUtils.intlFormat({
            amount: value || 0,
          })
        )}
      </Title>
    </Space>
  );
};
