import { Form, Input, Select, Switch } from 'antd';
import { useEffect } from 'react';

import {
  AppForm,
  closeDrawer,
  useAppDispatch,
  useDrawer,
  useForm,
} from '../../../../../core';
import { LINK_TYPES } from '../../../constants';
import { IMerchantLinkAttributes } from '../types';

type TProps = {
  isSubmitted: boolean;
  onFinish: (values: IMerchantLinkAttributes) => void;
};

export const LinkForm: React.FC<TProps> = ({ isSubmitted, onFinish }) => {
  const { form } = useForm({ drawerId: 'LinkDrawer' });
  const dispatch = useAppDispatch();
  const { resource } = useDrawer();

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    dispatch(closeDrawer());
  }, [isSubmitted, dispatch]);

  useEffect(() => {
    if (!resource || resource.type !== 'MerchantLink') {
      return;
    }

    form.setFieldsValue({ ...resource.attributes });
  }, [form, resource]);

  return (
    <AppForm form={form} onFinish={onFinish}>
      <Form.Item name={'title'} label="Title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'type'} label="Type" rules={[{ required: true }]}>
        <Select
          placeholder="Select Type"
          options={LINK_TYPES.map((type) => ({
            value: type,
            label: type.charAt(0).toUpperCase() + type.slice(1),
          }))}
        />
      </Form.Item>
      <Form.Item name={'data'} label="Data" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'status'} label="Status" valuePropName="checked">
        <Switch />
      </Form.Item>
    </AppForm>
  );
};
