import { IAppRoute } from '../../common';
import { UserRoleEnum } from '../users/enums';
import { EventList } from './views/list';
import { EventManage } from './views/manage';

export const eventRoutes: IAppRoute[] = [
  {
    path: '/events',
    roles: [UserRoleEnum.ADMIN],
    component: EventList,
  },
  {
    path: '/events/:eventId',
    roles: [UserRoleEnum.ADMIN],
    component: EventManage,
  },
];
