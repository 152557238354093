import {
  CalendarOutlined,
  CheckSquareOutlined,
  CodeOutlined,
  EditOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Card,
  Col,
  Divider,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { MdOutlineCategory } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { AppDrawer } from '../../../core';
import { Dayjs } from '../../../utils/date';
import { MerchantStatus } from '../components/MerchantStatus';
import { MerchantType } from '../components/MerchantType';
import { MerchantStatusEnum } from '../enums';
import { BranchList } from '../features/branches/views/list';
import { LinkList } from '../features/links/views/list';
import { OfferList } from '../features/offers/views/list';
import { useGetMerchantQuery } from '../store/merchant.api';
import { UpdateMerchant } from './update';

const { Meta } = Card;
const { Text } = Typography;

export const MerchantView: React.FC = () => {
  const { merchantId = '' } = useParams();
  const { data: merchant, isLoading } = useGetMerchantQuery({ id: merchantId });

  const tabs = [
    {
      label: 'Branches',
      key: '1',
      children: <BranchList />,
    },
    {
      label: 'Links',
      key: '2',
      children: <LinkList />,
    },
    {
      label: 'Offers',
      key: '3',
      children: <OfferList />,
    },
    {
      label: 'Redeems',
      key: '4',
      children: <div>Devices</div>,
    },
    {
      label: 'Notifications',
      key: '5',
      children: <div>Coming soon...</div>,
    },
  ];

  return (
    <>
      <AppDrawer title="Update Merchant" drawerId="MerchantDrawer">
        <UpdateMerchant />
      </AppDrawer>
      <Row gutter={16}>
        <Col span={10}>
          <Card
            size="small"
            actions={[
              <EditOutlined onClick={() => {}} key="edit" />,
              <SettingOutlined key="setting" />,
            ]}
          >
            {isLoading ? (
              <Skeleton avatar active />
            ) : (
              <Meta
                title={merchant?.data.attributes.name}
                description={
                  <Space direction="vertical">
                    <Text>{merchant?.data.attributes.about}</Text>
                    <MerchantStatus
                      status={
                        merchant?.data.attributes.status ||
                        MerchantStatusEnum.DRAFT
                      }
                    />
                  </Space>
                }
                avatar={<Avatar src={merchant?.data.attributes.logo} />}
              />
            )}
          </Card>
        </Col>
        <Col span={14}>
          <Card size="small" title="Merchant Details">
            {isLoading ? (
              <Skeleton avatar active />
            ) : (
              <Space direction="vertical">
                <Space>
                  <CodeOutlined />
                  <Text>Unique Code: {merchant?.data.attributes.code}</Text>
                </Space>
                <Space direction="horizontal">
                  <CalendarOutlined />
                  <Text>
                    {`End Date: ${Dayjs.fromUTC(
                      merchant?.data.attributes.end_date
                    ).format('MMM D, YYYY')}`}
                  </Text>
                </Space>
                <Space direction="horizontal">
                  <CheckSquareOutlined />
                  <Text
                    type={
                      merchant?.data.attributes.is_featured
                        ? 'success'
                        : 'danger'
                    }
                  >
                    Featured:{' '}
                    {merchant?.data.attributes.is_featured ? 'Yes' : 'No'}
                  </Text>
                </Space>
                <Space>
                  <CodeOutlined />
                  <Text>Merchant Type:</Text>
                  {merchant?.data.attributes.types &&
                    merchant?.data.attributes.types.map((type) => (
                      <MerchantType key={type.type} merchantType={type.type} />
                    ))}
                </Space>
                <Space wrap>
                  <MdOutlineCategory />
                  <Text>Categories:</Text>
                  {merchant?.data.relationships?.categories?.map((r) => {
                    const category = merchant?.included?.find(
                      (i) => i.id === r.id
                    );

                    return (
                      <Tag key={category?.id}>{category?.attributes.name}</Tag>
                    );
                  })}
                </Space>
              </Space>
            )}
          </Card>
        </Col>
      </Row>
      <Divider />
      <Tabs type="card" items={tabs} />
    </>
  );
};
