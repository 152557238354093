import { TableProps, Tag, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { DataTable } from '../../../core';
import { CurrencyUtils } from '../../../utils/currency';
import { useGetPaymentsQuery } from '../store/customers.api';
import { PaymentStatus } from './PaymentStatus';

const { Text } = Typography;

export const PaymentHistory = () => {
  const { customerId = '' } = useParams();

  const { data: payments, isLoading } = useGetPaymentsQuery({ id: customerId });

  const columns: TableProps['columns'] = [
    {
      title: 'Order ID',
      dataIndex: 'order_id',
      render: (orderId: string) =>
        orderId ? <Tag>{orderId}</Tag> : <Text type="danger">Missing</Text>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (paymentAmount: number) =>
        CurrencyUtils().intlFormat({ amount: paymentAmount }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <PaymentStatus status={status} />,
    },
    {
      title: 'Message',
      dataIndex: 'transaction',
      render: (transaction: Record<string, any>) =>
        transaction?.message || <Text type="danger">--</Text>,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (date: string) => new Date(date).toLocaleString(),
    },
  ];

  return (
    <DataTable isLoading={isLoading} columns={columns} data={payments || []} />
  );
};
