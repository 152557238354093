import { GoogleMap, Marker } from '@react-google-maps/api';
import { App, Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { SingleValue } from 'react-select';
import { IGooglePlace } from '../types';

type Tprops = {
  marker: string;
  onLocationChange: (location: google.maps.LatLng) => void;
  location?: google.maps.LatLng;
};

export const LocationFinder: React.FC<Tprops> = ({
  onLocationChange,
  marker,
  location,
}) => {
  const [selectedLatLng, setSelectedLatLng] = useState<any>(null);
  const { message } = App.useApp();

  const handleSelect = async (place: SingleValue<IGooglePlace>) => {
    if (!place) {
      return;
    }

    try {
      const results = await geocodeByPlaceId(place.value.place_id);
      const latLng = await getLatLng(results[0]);
      setSelectedLatLng(latLng);
      onLocationChange(new google.maps.LatLng(latLng.lat, latLng.lng));
    } catch (error) {
      console.error('Error fetching coordinates: ', error);
    }
  };

  const handleDragEnd = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) {
      message.error('Cannot get the location!');

      return;
    }

    message.success('New location selected successfully');

    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setSelectedLatLng({ lat: newLat, lng: newLng });
    onLocationChange(e.latLng);
  };

  const mapContainerStyle = {
    width: '100%',
    height: '300px',
  };

  const center = {
    lat: 30.033333,
    lng: 31.233334,
  };

  useEffect(() => {
    setSelectedLatLng(location);
  }, [location]);

  return (
    <Row>
      <Col span={24}>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <GooglePlacesAutocomplete
            apiKey={process.env.GOOGLE_API_KEY}
            selectProps={{
              value: null,
              onChange: handleSelect,
              placeholder: 'Search for location...',
            }}
            autocompletionRequest={{
              componentRestrictions: { country: 'eg' },
            }}
          />

          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={selectedLatLng || center}
            zoom={selectedLatLng ? 18 : 8}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              scrollwheel: false,
            }}
          >
            {selectedLatLng && (
              <Marker
                position={selectedLatLng}
                onDragEnd={handleDragEnd}
                icon={{
                  url: marker,
                  scaledSize: new window.google.maps.Size(50, 50),
                }}
                draggable
              />
            )}
          </GoogleMap>
        </Space>
      </Col>
    </Row>
  );
};
