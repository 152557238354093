import { ImportOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { App, Button, Flex, Space, TableProps, Tag } from 'antd';
import clipboard from 'clipboardy';
import { BsToggles } from 'react-icons/bs';
import { FaRegCopy, FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { TActionList, UUID } from '../../../../../common/types';

import {
  ActionListButton,
  AppDrawer,
  DataTable,
  drawerResource,
  openDrawer,
  useAppDispatch,
  useDrawer,
} from '../../../../../core';
import { useGetOffersQuery, useUpdateOfferMutation } from '../store/offer.api';
import { CreateOffer } from './create';
import { UpdateOffer } from './update';

export type TOfferDrawer = 'OfferDrawer';

export const OfferList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { merchantId = '' } = useParams<{ merchantId: string }>();
  const { data: offers, isFetching } = useGetOffersQuery({ merchantId });
  const { resource } = useDrawer();
  const { modal, message } = App.useApp();
  const [updateOffer] = useUpdateOfferMutation();

  const actions: TActionList['items'] = [
    {
      label: 'Toggle Status',
      key: 'toggle_status',
      icon: <BsToggles />,
      action: (record) => {
        modal.confirm({
          title: 'Toggle link status',
          content: 'Are you sure you want to toggle status?',
          okType: 'primary',
          onOk: () => {
            updateOffer({
              id: record.key,
              status: !record.status,
              merchantId,
            });
          },
        });
      },
    },
    {
      key: 'edit',
      label: 'Edit',
      icon: <FaRegEdit />,
      action: (record) => {
        const { key: id, ...reset } = record;

        dispatch(
          drawerResource({
            id: record.key,
            type: 'Offer',
            attributes: reset,
          })
        );
        dispatch(openDrawer('OfferDrawer'));
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      disabled: true,
      icon: <RiDeleteBin5Fill />,
      action: (record) => {},
    },
    {
      label: 'Copy Link',
      key: 'copy_link',
      icon: <FaRegCopy />,
      action: (record) => {
        clipboard.write(
          `https://superapp.rahetbally.com/merchants/${record.merchant.id}/offers/${record.key}`
        );
        message.success('Link copied to clipboard');
      },
    },
  ];

  const columns: TableProps['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Active',
      dataIndex: 'status',
      render: (status: boolean) => (
        <Tag color={status ? 'green' : 'red'}>{status ? 'Yes' : 'No'}</Tag>
      ),
    },
    {
      title: 'Featured',
      dataIndex: 'is_featured',
      render: (status: boolean) => (
        <Tag color={status ? 'green' : 'red'}>{status ? 'Yes' : 'No'}</Tag>
      ),
    },
    {
      title: 'Flash Deal',
      dataIndex: 'is_flash_deal',
      render: (status: boolean) => (
        <Tag color={status ? 'green' : 'red'}>{status ? 'Yes' : 'No'}</Tag>
      ),
    },
    {
      title: 'Packages',
      dataIndex: 'packages',
      width: 230,
      render: (packages: Array<{ id: UUID; name: string }>) => (
        <>
          {packages.map((item) => (
            <Tag key={item.id} color="geekblue">
              {item.name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      align: 'center',
      width: 110,
      render: (record) => <ActionListButton items={actions} record={record} />,
    },
  ];

  return (
    <>
      <AppDrawer
        title={resource ? 'Update Offer' : 'Add New Offer'}
        drawerId={'OfferDrawer'}
      >
        {resource ? <UpdateOffer /> : <CreateOffer />}
      </AppDrawer>
      <Space direction="vertical">
        <Flex justify="end">
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => dispatch(openDrawer('OfferDrawer'))}
            >
              <PlusSquareOutlined /> Add Offer
            </Button>
            <Button type="primary">
              <ImportOutlined /> Import Offers
            </Button>
          </Space>
        </Flex>
        <DataTable
          isLoading={isFetching}
          columns={columns}
          data={offers || []}
        />
      </Space>
    </>
  );
};
