import { useCallback } from 'react';
import { FormUtils } from '../../merchants/utils/FormUtils';
import { BannerForm } from '../components/BannerForm';
import { useCreateBannerMutation } from '../store/banner.api';

export const CreateBanner: React.FC = () => {
  const [createBanner, { isSuccess }] = useCreateBannerMutation();

  const onFinish = useCallback(
    (attributes: Record<string, any>) => {
      const { cover, ...rest } = attributes;

      const payload = {
        ...rest,
        ...(cover && { cover: FormUtils().getFileName(cover) }),
      };

      createBanner(payload);
    },
    [createBanner]
  );

  return <BannerForm onFinish={onFinish} isSubmitted={isSuccess} />;
};
