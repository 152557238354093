import { read, utils } from 'xlsx';
import { TFileInfo } from '../../../common/types';

export const FileUtils = () => {
  return {
    isFileUploaded: (info: TFileInfo) =>
      info.fileList?.length === 0 ||
      !info.fileList[0].response ||
      info.fileList[0].response.error,
    readExcelFile: (
      file: File,
      callback: (data: Record<string, any>) => void
    ): void => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as any);
        const wb = read(data, { type: 'array', dateNF: 'yyyy-mm-dd' });

        const result = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
          raw: false,
          dateNF: 'yyyy-mm-dd',
        });

        callback(result);
      };
      reader.readAsArrayBuffer(file);
    },
    getFileName: (fileList: TFileInfo['fileList']): string | undefined =>
      fileList[0]?.response?.filename,
  };
};
