import { TResponse } from '../../../../../common/types';
import { superAppApi } from '../../../../../core';
import { IMerchantBranch, IMerchantBranchAttributes } from '../types';

type TUpdateBranchArgs = Partial<IMerchantBranchAttributes> & {
  merchantId: string;
  id: string;
};

const branchEndpoints = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    createBranch: build.mutation<void, TUpdateBranchArgs>({
      query: ({ merchantId, ...data }) => ({
        url: `/merchants/${merchantId}/branches`,
        method: 'POST',
        body: {
          data: {
            type: 'MerchantBranch',
            attributes: data,
          },
        },
      }),
      invalidatesTags: ['Branches'],
    }),
    updateBranch: build.mutation<void, TUpdateBranchArgs>({
      query: ({ merchantId, id, ...attributes }) => ({
        url: `/merchants/${merchantId}/branches/${id}`,
        method: 'PATCH',
        body: {
          data: {
            id,
            type: 'MerchantBranch',
            attributes,
          },
        },
      }),
      invalidatesTags: ['Branches'],
    }),
    getBranches: build.query<IMerchantBranch[], { merchantId: string }>({
      query: ({ merchantId }) => ({
        url: `/merchants/${merchantId}/branches`,
      }),
      providesTags: ['Branches'],
      transformResponse: (response: TResponse<IMerchantBranch[]>) =>
        response.data,
    }),
  }),
});

export const {
  useGetBranchesQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
} = branchEndpoints;
