export const API_BASEURL = process.env.REACT_APP_API_BASEURL || 'https://api.rahetbally.com/v1';
export const API_UPLOAD_BASEURL = `${API_BASEURL}/upload`;
export const EXCEL_ACCEPTED_MIME_TYPES =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv';

export const DEFAULT_PAGE_LIMIT = '5';
export const DEFAULT_PAGE_NUMBER = '1';

export const ISO8601_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const APP_CODE = 'RAHETBALLY_SUPER_APP';

export const keyPrefix = (key: string) => `${APP_CODE}_${key}`.toUpperCase();

export const ACCESS_TOKEN_KEY = keyPrefix('access_token');
export const REFRESH_TOKEN_KEY = keyPrefix('refresh_token');
export const EXPIRE_IN_KEY = keyPrefix('expire_in');
export const IS_SIGNED_IN_KEY = keyPrefix('is_signed_in');

export const DEFAULT_TIMEZONE = process.env.DEFAULT_TIMEZONE || 'Africa/Cairo';
