import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { LinkForm } from '../components/LinkForm';
import { useCreateLinkMutation } from '../store/link.api';

export const CreateLink: React.FC = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const [createLink, { isSuccess }] = useCreateLinkMutation();

  const onFinish = useCallback(
    (values: any) => {
      createLink({
        ...values,
        merchantId,
      });
    },
    [createLink, merchantId]
  );

  return <LinkForm isSubmitted={isSuccess} onFinish={onFinish} />;
};
