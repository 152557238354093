export const RahetBallyLogo = () => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
      className="w-10 h-10"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: '\n      .cls-1{fill:#ffffff;}\n    ',
          }}
        />
      </defs>
      <title>RB rebranding</title>
      <path
        className="cls-1"
        d="M162.6,440c2.32-56.85,26.18-91.17,67.47-109.16L172.84,608.45h57.23l30.25-154.93c9.81-2,17.58-2.46,24.53-1.64,44.14,11,9.4,121.82,2,156.57h54.37c11-44.56,41.29-141-12.27-175,29.84-18.81,51.51-55.19,36.8-89.53-26.17-57.64-130.41-55.19-193.36-4.09-32.7,27-53.55,65-46.6,117.33ZM286.48,321.89c21.67,3.68,38,17.17,38.84,43.33,0,39.65-25.35,60.5-59.68,63.77Z"
      />
      <path
        className="cls-1"
        d="M249.31,755.35c31.06-18,54-56,37.61-90.34C253,605.09,184.55,616.53,167,625.39l-3.45,18.77L97.65,929.9H165.1c56,.41,111.19-4.08,135.31-55.59,12.67-26.16,12.67-56,0-79.71C290.6,776.2,273,761.49,249.31,755.35ZM210.47,644.16c20.45,3.27,36.8,16,36.8,42.11.81,38-23.72,58.86-57.64,63Zm-51.1,258,24.95-128.36C282.42,765.57,273,903.74,159.37,902.11Z"
      />
      <path
        className="cls-1"
        d="M904.6,725.51l-29,143.89c-19.62,34.34-67.45,60.5-58.87,16,9-45,19.63-96.89,32.3-159.84H791L765.21,855.1a131.84,131.84,0,0,0-2.9,25C742,908.76,704.2,926,711.65,887.39c9.41-47,36-174.14,49.88-243.63H706.34L663.42,858.37c-.68,3.44-1.23,6.76-1.64,9.94-16.66,35-66.07,62.77-57.64,19.08,9.41-47,36-174.14,49.88-243.63H598.83L555.91,858.37a133.08,133.08,0,0,0-2.64,22.28c-21.74,28.05-59.87,44.24-52.55,6.33,9.4-47,18-83.39,30.66-145.93l3.27-15.54H431.22C358.05,728,325.76,793.78,320,859.59c-7.36,84.62,85.84,96.48,129.17,31.89,4.19,55.44,66.06,50.64,105.88,9.26,11.27,47.72,71.1,37.9,106.37-6.25,7.1,52.52,65.77,46.58,102.95,5.37,11.54,45.95,67.65,40.94,104.22,3.06l-17.58,85.85c-4.5,22.48-39.07,3.26-49.29-5.33l-44.22,39c122.23,34.34,156.05-48.78,174-145.26l30.66-151.66Zm-454.17,139c-56.41,76-83.39,37.61-67.85-36.38,14.3-66.22,62.54-110.37,87.48-65Z"
      />
      <circle
        className="cls-1"
        cx="992.73"
        cy="877.92"
        r="35.99"
        transform="translate(-148.11 203.57) rotate(-10.9)"
      />
      <path
        className="cls-1"
        d="M1051.67,416.21h25.76l11.85-26.27h-33.11l12.67-68.05-58,12.06-11,56H980.14l-10.22,26.27h24.53l-21.67,112A112.6,112.6,0,0,0,969,545.32c-19.63,28.23-50.08,48.1-79.15,34.51-17.57-8.58-24.93-31.48-25.34-51.1,35.16,1.23,69.5-11.44,87.89-45.37,12.26-24.12,11.86-55.19-9-71.95-23.3-19.62-76.44-9.4-107.51,38.83-21.63,34.43-30.55,80.9-20,115-21.22,24.55-51.86,35.88-45.42-1,5.31-27.39,10.63-58,15.53-86.67,16.76-89.11-51.5-92.38-95.25-46.6L712.45,322.3H658.08L609.24,562.71c-22,25.81-56.69,39.1-49.68,2.81,9.4-47,18-83.39,30.66-145.93l3.27-15.54H490.07C416.9,406.5,384.6,472.32,378.88,538.13c-7.36,84.62,85.84,96.48,129.17,31.89,3.86,51,56.55,51,96,18.47L600,608h56l28.62-143.49c18.4-34.33,59.28-60.09,50.69-15.94-5.31,32.29-11.85,66.22-18.8,105.06-11.3,62.81,60.61,74.82,105.92,26.82,10.13,17.69,27.61,29.57,53.91,30.41,37.34,1,66.28-8.54,92.71-42.62,8.23,49.15,70.21,52.54,84.23,21.86l-1.3-.22c-18.09,7.51-32.86,3.27-27.72-30.71ZM866.15,505c4.5-22.89,11.86-41.28,20.45-54.37,14.71-23.3,32.7-31.06,40.87-24.93,10.22,8.17,2.86,85.84-61.72,83.8C865.75,508.29,866.15,506.66,866.15,505ZM509.28,543c-56.41,76-83.39,37.61-67.86-36.38,14.31-66.22,62.55-110.37,87.48-65Z"
      />
    </svg>
  );
};
