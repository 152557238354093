import { Form, FormInstance, FormProps } from 'antd';
import { useEffect } from 'react';
import { useDrawer } from '../hooks/useAppDrawer';

type TFormProps = {
  children: React.ReactNode;
  form: FormInstance<any>;
} & FormProps;

export const AppForm: React.FC<TFormProps> = ({ children, form, ...reset }) => {
  const { open } = useDrawer();

  useEffect(() => {
    if (open) {
      return;
    }

    form.resetFields();
  }, [form, open]);

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      style={{ maxWidth: 600 }}
      form={form}
      {...reset}
    >
      {children}
    </Form>
  );
};
