import { superAppApi } from '../../../core';
import { IOffer } from '../../merchants/features/offers/types';

const redeemApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    redeemOffer: build.mutation<
      void,
      { redeem_code: string; cart_total: number }
    >({
      query: ({ redeem_code, cart_total }) => ({
        url: `/merchants/offers/redeem`,
        method: 'POST',
        body: {
          data: {
            attributes: {
              redeem_code,
              cart_total,
            },
          },
        },
      }),
    }),
    validateRedeem: build.mutation<
      {
        offer: IOffer;
        customer: { id: string; full_name: string; phone: string };
      },
      { redeem_code: string; merchantId: string; cart_total: number }
    >({
      query: ({ merchantId, cart_total, redeem_code }) => ({
        url: `/merchants/${merchantId}/offers/find-redeem-request`,
        method: 'POST',
        body: {
          data: {
            attributes: {
              cart_total,
              redeem_code,
            },
          },
        },
      }),
      transformResponse: (response: {
        data: {
          offer: IOffer;
          customer: { id: string; full_name: string; phone: string };
        };
      }) => response.data,
    }),
  }),
});

export const { useRedeemOfferMutation, useValidateRedeemMutation } = redeemApi;
