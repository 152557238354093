import { Tag } from 'antd';
import { ISubscriptionAttributes } from '../types';
import { SubscriptionPackageEnum } from '../../subscriptions/enums';

type IProps = {
  subscription?: ISubscriptionAttributes;
};

export const SubscriptionBadge: React.FC<IProps> = ({ subscription }) => {
  let color = 'default';
  let name = 'LITE';

  switch (subscription?.package.name) {
    case SubscriptionPackageEnum.PLUS:
      color = 'geekblue';
      name = SubscriptionPackageEnum.PLUS;
      break;
    case SubscriptionPackageEnum.PLUS_ORANGE_100:
      color = 'orange';
      name = SubscriptionPackageEnum.PLUS_ORANGE_100;
      break;
    case SubscriptionPackageEnum.PLUS_CIB_CLIENT:
      color = 'orange';
      name = SubscriptionPackageEnum.PLUS_CIB_CLIENT;
      break;
    case SubscriptionPackageEnum.PLSU_CIB_STAFF:
      color = 'orange';
      name = SubscriptionPackageEnum.PLSU_CIB_STAFF;
      break;
  }

  return <Tag color={color}>{name}</Tag>;
};
