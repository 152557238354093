import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useDrawer } from '../../../../../core';
import { LinkForm } from '../components/LinkForm';
import { useUpdateLinkMutation } from '../store/link.api';

export const UpdateLink: React.FC = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const [updateLink, { isSuccess }] = useUpdateLinkMutation();
  const { resource } = useDrawer();

  const onFinish = useCallback(
    (values: any) => {
      updateLink({
        ...values,
        linkId: resource?.id,
        merchantId,
      });
    },
    [updateLink, resource?.id, merchantId]
  );

  return <LinkForm onFinish={onFinish} isSubmitted={isSuccess} />;
};
