import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { AppSetting } from './views';

export const settingRoutes: IAppRoute[] = [
  {
    path: '/settings',
    component: AppSetting,
    roles: [UserRoleEnum.ADMIN],
  },
];
