import { Button, Space, TableProps } from 'antd';
import { useParams } from 'react-router-dom';

import { DataTable } from '../../../core';
import { CurrencyUtils } from '../../../utils/currency';
import { OfferComponentTypeEnum } from '../../merchants/features/offers/enums';
import { useGetRedeemsQuery } from '../store/customers.api';
import { RedeemPaymentMethod } from './RedeemPaymentMethod';
import { RedeemStatus } from './RedeemStatus';

export const SpendingList = () => {
  const { customerId = '' } = useParams<string>();
  const { data: redeems } = useGetRedeemsQuery({ id: customerId });
  const columns: TableProps['columns'] = [
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <RedeemStatus status={status} />,
    },
    {
      title: 'Payment Method',
      dataIndex: 'payment_method',
      render: (paymentMethod: string) => (
        <RedeemPaymentMethod method={paymentMethod} />
      ),
    },
    {
      title: 'Cart Total',
      dataIndex: 'cart_total',
      render: (cartTotal: number) =>
        CurrencyUtils().intlFormat({ amount: cartTotal }),
    },
    {
      title: 'Offer',
      dataIndex: 'offer',
      render: (offer: { component: { type: string; value: number } }) => (
        <Space.Compact>
          <Button size="small" type="primary">
            {offer.component.type
              .split('_')
              .map((c) => c.toUpperCase())
              .join(' ')}
          </Button>
          <Button size="small" type="dashed">
            {`${offer.component.value}`}
            {offer.component.type === OfferComponentTypeEnum.FIXED_PERCENTAGE
              ? '%'
              : ''}
          </Button>
        </Space.Compact>
      ),
      ellipsis: true,
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      render: (merchant: { name: string }) => merchant.name,
    },
  ];

  return <DataTable columns={columns} data={redeems || []} />;
};
