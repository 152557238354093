import { TPaginationParams, TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';
import { IGetMerchantArgs, IMerchant, IMerchantAttributes } from '../types';

export const merchantsApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMerchant: builder.query<TResponse<IMerchant>, IGetMerchantArgs>({
      query: ({ id }) =>
        id && {
          url: `/merchants/${id}`,
        },
      providesTags: ['Merchant'],
    }),
    getMerchants: builder.query<TResponse<IMerchant[]>, TPaginationParams>({
      query: ({ page, limit, search }) => ({
        url: `/merchants/new?include=types`,
        params: { page, limit, search },
      }),
      providesTags: (result, error, arg) => ['Merchants'],
    }),
    createMerchant: builder.mutation<void, Partial<IMerchantAttributes>>({
      query: (attributes) => ({
        url: `/merchants`,
        method: 'POST',
        body: {
          data: {
            type: 'Merchant',
            attributes,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => ['Merchants'],
    }),
    updateMerchant: builder.mutation<void, Partial<IMerchant>>({
      query: ({ id, ...attributes }) => ({
        url: `/merchants/${id}`,
        method: 'PATCH',
        body: {
          data: {
            id,
            type: 'Merchant',
            attributes,
          },
        },
      }),
      invalidatesTags: () => ['Merchants', 'Merchant'],
    }),
  }),
});

export const {
  useGetMerchantQuery,
  useGetMerchantsQuery,
  useCreateMerchantMutation,
  useUpdateMerchantMutation,
} = merchantsApi;
