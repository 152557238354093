import { ExportOutlined } from '@ant-design/icons';
import { Card, Col, Row, Space, Typography } from 'antd';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { API_BASEURL } from '../../../common/constants';
import { useAuthUser } from '../../../core';
import { Url } from '../../../utils/url';
import { UserRoleEnum } from '../../users/enums';
import { useGetReportsQuery } from '../store/reports.api';
import { TReportItem } from '../types';

const { Link, Text } = Typography;

export const Reports: React.FC = () => {
  const {
    auth: { access_token: accessToken } = {},
    user: { attributes: { roles = [] } = {}, meta } = {},
  } = useAuthUser();

  const { data } = useGetReportsQuery();
  const [reports, setReports] = useState<TReportItem[]>([]);

  useEffect(() => {
    const reports = data?.map((report) => ({
      key: report.id,
      label: report.attributes.title,
      url: Url.buildUrl({
        baseUrl: API_BASEURL,
        pathSegments: ['reports', report.id, 'export'],
        queryParams: {
          ...(report.attributes.query || {}),
          token: accessToken,
          report: report.attributes.view,
        },
      }),
      roles: report.attributes.roles,
    }));

    if (!reports) {
      return;
    }

    if (meta?.merchant?.id) {
      reports.push({
        key: uniqueId(),
        label: 'Redeems Report',
        url: `${API_BASEURL}/merchants/export-redeems?token=${accessToken}&filter[merchant_id]=${meta?.merchant?.id}`,
        roles: [UserRoleEnum.MERCHANT],
      });
    }

    setReports(reports || []);
  }, [data]);

  const filteredReports = useMemo(
    () =>
      reports.filter((report) =>
        report.roles.some((e) => roles.filter((r) => r.role === e).length)
      ),
    [roles, reports]
  );

  return (
    <Row>
      <Col span={12}>
        <Card>
          <Space direction="vertical">
            {filteredReports.map((report) => (
              <Link
                key={report.key}
                href={report.url}
                target="_blank"
                rel="noreferrer"
              >
                <Space>
                  <FaArrowAltCircleRight size={14} style={{ marginTop: 8 }} />
                  <Text style={{ fontWeight: 500, fontSize: 16 }}>
                    {report.label}
                  </Text>
                  <ExportOutlined />
                </Space>
              </Link>
            ))}
          </Space>
          {filteredReports.length === 0 && <li>No reports found</li>}
        </Card>
      </Col>
    </Row>
  );
};
