import { Table, TableProps } from 'antd';
import { useAppDispatch, useImportWizard } from '../../../../../core';

type TProps = {
  taskId: string;
  links: any;
};

export const LinksImportTable: React.FC<TProps> = ({ taskId, links }) => {
  const { merchants } = useImportWizard();
  const dispatch = useAppDispatch();

  const columns: TableProps['columns'] = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      ellipsis: true,
    },
    {
      title: 'Data',
      dataIndex: 'data',
    },

    {
      title: 'Merchant',
      dataIndex: 'merchant',
    },
  ];

  return (
    <Table pagination={false} columns={columns} dataSource={links || []} />
  );
};
