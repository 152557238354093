import { TResponse } from '../../../common';
import { superAppApi } from '../../../core';
import { IReport } from '../types';

const reportsApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getReports: build.query<IReport[], void>({
      query: () => ({
        url: '/reports',
        method: 'GET',
      }),
      transformResponse: (response: TResponse<IReport[]>) => response.data,
    }),
  }),
});

export const { useGetReportsQuery } = reportsApi;
