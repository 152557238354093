import { Table, TableProps } from 'antd';
import React from 'react';
import { TEntity } from '../../common/types';
import { useAppParams } from '../hooks/useAppParams';

type TProps = {
  data?: TEntity[];
  columns: TableProps['columns'];
  total?: number;
  isLoading?: boolean;
  rowSelection?: TableProps['rowSelection'];
  paginated?: boolean;
};

export const DataTable: React.FC<TProps> = ({
  data,
  columns,
  total,
  isLoading,
  rowSelection,
  paginated = true,
}) => {
  const { page, limit, search, setParams } = useAppParams();

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={data?.map((record) => ({
        ...record.attributes,
        ...(record.relationships && { relationships: record.relationships }),
        key: record.id,
      }))}
      rowSelection={rowSelection}
      tableLayout="fixed"
      pagination={
        paginated && {
          current: page,
          pageSize: limit,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          onChange(page, limit) {
            setParams({
              page: page.toString(),
              limit: limit.toString(),
              ...(search && { search }),
            });
          },
        }
      }
    />
  );
};
