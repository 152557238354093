import { TResponse } from '../../../common';
import { superAppApi } from '../../../core';
import { IOccasion, IOccasionFormValues, IOccasionStatistics } from '../types';
import { formatPayload } from './formatter';

const occassionApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getOccasions: build.query<IOccasion[], void>({
      query: () => ({
        url: '/occasions',
        method: 'GET',
      }),
      transformResponse: (response: TResponse<IOccasion[]>) => response.data,
      providesTags: ['Occasions'],
    }),
    createOccasion: build.mutation<void, IOccasionFormValues>({
      query: (attributes) => {
        return {
          url: '/occasions',
          method: 'POST',
          body: {
            data: {
              attributes: formatPayload(attributes),
            },
          },
        };
      },
      invalidatesTags: ['Occasions'],
    }),
    updateOccasion: build.mutation<void, IOccasionFormValues & { id: string }>({
      query: ({ id, ...attributes }) => {
        return {
          url: `/occasions/${id}`,
          method: 'PATCH',
          body: {
            data: {
              id,
              attributes: formatPayload(attributes),
            },
          },
        };
      },
      invalidatesTags: ['Occasions'],
    }),
    getOccasionStatistics: build.query<IOccasionStatistics, { id: string }>({
      query: ({ id }) => ({
        url: `/occasions/${id}/statistics`,
        method: 'GET',
      }),
      transformResponse: (response: TResponse<IOccasionStatistics>) =>
        response.data,
    }),
  }),
});

export const {
  useGetOccasionsQuery,
  useCreateOccasionMutation,
  useUpdateOccasionMutation,
  useGetOccasionStatisticsQuery,
} = occassionApi;
