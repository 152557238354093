import { App, DatePicker, Form, Input, Modal, Select, TableProps } from 'antd';
import { includes } from 'lodash';
import { useCallback, useState } from 'react';
import { AiOutlineRobot } from 'react-icons/ai';
import { TActionList } from '../../../common/types';

import { ActionListButton, DataTable, useAppParams } from '../../../core';
import { ICustomerAttributes } from '../../customers/types';
import { useGetPackagesQuery } from '../../subscriptions/store/package.api';
import {
  useSubscribeMutation,
  useUnsubscribeMutation,
} from '../../subscriptions/store/subscription.api';
import { StaffDepartment } from '../components/Department';
import { useGetStaffListQuery } from '../store/staff.api';

export const StaffList: React.FC = () => {
  const { page, limit } = useAppParams();
  const { data: staff, isFetching } = useGetStaffListQuery({ page, limit });
  const { message } = App.useApp();
  const [subscribeForm] = Form.useForm();
  const [unsubscribeForm] = Form.useForm();
  const { data: packages } = useGetPackagesQuery();
  const [subscribeVisible, setSubscribeVisible] = useState(false);
  const [unsubscribeVisible, setUnsubscribeVisible] = useState(false);
  const [user, setUser] = useState<ICustomerAttributes['user']>();
  const [subscribe, { isLoading: isLoadingSubscribe }] = useSubscribeMutation();
  const [unsubscribe, { isLoading: isLoadingUnsubscribe }] =
    useUnsubscribeMutation();

  const onSubscribeClose = useCallback(() => {
    setSubscribeVisible(false);
    setUnsubscribeVisible(false);
    subscribeForm.resetFields();
    unsubscribeForm.resetFields();
  }, [subscribeForm, unsubscribeForm]);

  const onSubscribeFinish = useCallback(
    (values: any) => {
      if (!user) {
        message.error('User not found!');
        return;
      }

      subscribe({
        userId: user.id,
        package_id: values.package_id,
        expires_at: values.expires_at,
        admin_notes: values.admin_notes,
      }).then(() => {
        onSubscribeClose();
      });
    },
    [message, onSubscribeClose, subscribe, user]
  );

  const onUnsubscribeFinish = useCallback(
    (values: any) => {
      if (!user) {
        message.error('User not found!');
        return;
      }

      unsubscribe({
        userId: user.id,
        admin_notes: values.admin_notes,
      }).then(() => {
        onSubscribeClose();
      });
    },
    [message, onSubscribeClose, unsubscribe, user]
  );

  const actions = (record: ICustomerAttributes): TActionList['items'] => {
    return [
      {
        label: 'Start Subscribe',
        key: 'subscribe',
        icon: <AiOutlineRobot color="#00b96b" />,
        action: (record: ICustomerAttributes) => {
          setUser(record.user);
          setSubscribeVisible(true);
        },
        disabled: record.user.subscription !== undefined,
      },
      {
        label: 'End Subscribe',
        key: 'end_subscribe',
        icon: <AiOutlineRobot color="#be3b07" />,
        action: (record: ICustomerAttributes) => {
          setUser(record.user);
          setUnsubscribeVisible(true);
        },
        disabled: record.user.subscription === undefined,
      },
    ];
  };

  const columns: TableProps['columns'] = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      render: (department: string) => (
        <StaffDepartment department={department} />
      ),
    },
    {
      title: 'Actions',
      render: (_, record: ICustomerAttributes) => (
        <ActionListButton items={actions(record)} record={record} />
      ),
      width: 110,
      align: 'center',
    },
  ];

  return (
    <>
      <Modal
        title={`Start Subscribe for ${user?.full_name}`}
        width={450}
        open={subscribeVisible}
        onOk={() => subscribeForm.submit()}
        onCancel={onSubscribeClose}
        confirmLoading={isLoadingSubscribe}
      >
        <Form
          form={subscribeForm}
          layout="vertical"
          onFinish={onSubscribeFinish}
        >
          <Form.Item
            name="package_id"
            label="Package"
            required
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Subscription Package"
              options={packages
                ?.filter(
                  (item) =>
                    !includes(
                      ['Lite', 'Plus - orange 70%'],
                      item.attributes.name
                    )
                )
                .map((item) => ({
                  value: item.id,
                  label: item.attributes.name,
                }))}
            />
          </Form.Item>
          <Form.Item
            name="expires_at"
            label="Expire Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="admin_notes"
            label="Admin Notes"
            required
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder="Write a descriptive message to explain why this subscription created manually." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={`End Subscription for ${user?.full_name}`}
        width={450}
        open={unsubscribeVisible}
        onOk={() => unsubscribeForm.submit()}
        onCancel={onSubscribeClose}
        confirmLoading={isLoadingUnsubscribe}
      >
        <Form
          form={unsubscribeForm}
          layout="vertical"
          onFinish={onUnsubscribeFinish}
        >
          <Form.Item
            name="admin_notes"
            label="Admin Notes"
            required
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder="Write a descriptive message to explain why this subscription ended manually." />
          </Form.Item>
        </Form>
      </Modal>
      <DataTable columns={columns} data={staff || []} isLoading={isFetching} />
    </>
  );
};
