import { createBrowserRouter } from 'react-router-dom';
import { bannerRoutes } from '../features/banners/routes';
import { categoryRoutes } from '../features/categories/routes';
import { customerRoutes } from '../features/customers/routes';
import { dashboardRoutes } from '../features/dashboard/routes';
import { importRoutes } from '../features/merchants/features/import/routes';
import { merchantRoutes } from '../features/merchants/routes';
import { notificationRoutes } from '../features/notifications/routes';
import { eventRoutes } from '../features/occasions';
import { redeemRoutes } from '../features/redeem/routes';
import { reportRoutes } from '../features/reports/routes';
import { settingRoutes } from '../features/settings/routes';
import { staffRoutes } from '../features/staff/routes';
import { userRoutes } from '../features/users/routes';

export const appRoutes = [
  ...dashboardRoutes,
  ...categoryRoutes,
  ...merchantRoutes,
  ...userRoutes,
  ...settingRoutes,
  ...customerRoutes,
  ...staffRoutes,
  ...importRoutes,
  ...redeemRoutes,
  ...bannerRoutes,
  ...notificationRoutes,
  ...reportRoutes,
  ...eventRoutes,
];

export const router = createBrowserRouter(appRoutes);
