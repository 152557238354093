import {
  ExportOutlined,
  ImportOutlined,
  PlusSquareOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import {
  App,
  Button,
  Flex,
  Input,
  Space,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import clipboard from 'clipboardy';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { FaRegCopy, FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { TiUserAdd } from 'react-icons/ti';
import { NavLink, useNavigate } from 'react-router-dom';
import { API_BASEURL } from '../../../common/constants';
import { IRelationship, TActionList } from '../../../common/types';
import {
  ActionListButton,
  AppDrawer,
  axiosApi,
  DataTable,
  drawerResource,
  openDrawer,
  useAppDispatch,
  useAppParams,
  useAuthUser,
  useDrawer,
} from '../../../core';
import { MerchantStatus } from '../components/MerchantStatus';
import { MerchantType } from '../components/MerchantType';
import { PasswordModal } from '../components/password_modal';
import { MerchantStatusEnum, MerchantTypeEnum } from '../enums';
import { useGetMerchantsQuery } from '../store/merchant.api';
import { IMerchantAttributes } from '../types';
import { CreateMerchant } from './create';
import { UpdateMerchant } from './update';

const { Link, Text } = Typography;
const { Search } = Input;

export type TMerchantDrawer = 'MerchantDrawer';

export const MerchantList: React.FC = () => {
  const navigate = useNavigate();
  const { page, limit, search, setParams } = useAppParams();
  const dispatch = useAppDispatch();
  const { resource } = useDrawer();
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  const { message, modal } = App.useApp();

  const { data: merchants, isFetching } = useGetMerchantsQuery({
    page,
    limit,
    search,
  });

  const actions: TActionList['items'] = [
    {
      label: 'Manage',
      key: 'manage',
      icon: <TruckOutlined />,
      action: (record) => {
        navigate(`/merchants/${record.key}`);
      },
    },
    {
      label: 'Edit',
      key: 'edit',
      icon: <FaRegEdit />,
      action: (record) => {
        const { key: id, relationships, ...reset } = record;

        dispatch(
          drawerResource({
            id,
            type: 'Merchant',
            attributes: reset,
            ...(relationships && { relationships }),
          })
        );

        dispatch(openDrawer('MerchantDrawer'));
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <RiDeleteBin5Fill />,
      disabled: true,
      action: (record) => {},
    },
    {
      label: 'Export Report',
      key: 'export_redeems_report',
      icon: <ExportOutlined />,
      action: (record) => {
        window.open(
          encodeURI(
            `${API_BASEURL}/merchants/export-redeems?token=${accessToken}&filter[merchant_id]=${record.key}`
          ),
          '_blank'
        );
      },
    },
    {
      label: 'Copy Link',
      key: 'copy_link',
      icon: <FaRegCopy />,
      action: (record) => {
        clipboard.write(
          `https://superapp.rahetbally.com/merchants/${record.key}`
        );
        message.success('Link copied to clipboard');
      },
    },
    {
      label: 'Reset User',
      key: 'add_user',
      icon: <TiUserAdd />,
      action: (record) => {
        modal.confirm({
          title: 'Reset Merchant User',
          content: 'Are you sure you want to reset the merchant user?',
          okType: 'primary',
          onOk: () => {
            axiosApi
              .post(
                `/merchants/${record.key}/reset-user`,
                {},
                { access_token: accessToken }
              )
              .then(({ data: response }) => {
                modal.success({
                  width: 500,
                  title: 'Success',
                  content: (
                    <PasswordModal
                      username={record.code}
                      password={response.data.password}
                    />
                  ),
                });
              });
          },
        });
      },
    },
  ];

  const columns: TableProps<IMerchantAttributes>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      render: (name: string, record: IMerchantAttributes) =>
        !record.logo && !record.cover ? (
          <Space direction="vertical" size={2}>
            <Text>{name}</Text>
            <Tag color="red">Missing logo and cover</Tag>
          </Space>
        ) : (
          <Text>{name}</Text>
        ),
    },
    {
      title: 'About',
      dataIndex: 'about',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => <MerchantStatus status={status} />,
      onFilter: (value, record) => record.status === value,
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
        multiple: 2,
      },
      filters: [
        {
          text: <MerchantStatus status={MerchantStatusEnum.DRAFT} />,
          value: MerchantStatusEnum.DRAFT,
        },
        {
          text: <MerchantStatus status={MerchantStatusEnum.ARCHIVED} />,
          value: MerchantStatusEnum.ARCHIVED,
        },
        {
          text: <MerchantStatus status={MerchantStatusEnum.PENDING} />,
          value: MerchantStatusEnum.PENDING,
        },
        {
          text: <MerchantStatus status={MerchantStatusEnum.PUBLISHED} />,
          value: MerchantStatusEnum.PUBLISHED,
        },
      ],
    },
    {
      title: 'Featured',
      dataIndex: 'is_featured',
      render: (is_featured: boolean) =>
        is_featured ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
      onFilter: (value, record) => record.is_featured === value,
      sorter: {
        compare: (a, b) => (a.is_featured ? 1 : 0) - (b.is_featured ? 1 : 0),
        multiple: 3,
      },
      filters: [
        {
          text: 'Featured',
          value: true,
        },
      ],
    },
    {
      title: 'Types',
      render: (_, record: any) => {
        const relationships = record.relationships.types as IRelationship[];
        const types =
          merchants?.included?.filter((item) =>
            relationships?.map((item) => item.id).includes(item.id)
          ) || [];

        return types.map((item) => (
          <MerchantType key={item.id} merchantType={item.attributes.type} />
        ));
      },
      filters: [
        {
          text: <MerchantType merchantType={MerchantTypeEnum.IN_STORE} />,
          value: MerchantTypeEnum.IN_STORE,
        },
        {
          text: <MerchantType merchantType={MerchantTypeEnum.ONLINE} />,
          value: MerchantTypeEnum.ONLINE,
        },
        {
          text: <MerchantType merchantType={MerchantTypeEnum.SOCIAL_MEDIA} />,
          value: MerchantTypeEnum.SOCIAL_MEDIA,
        },
      ],
    },
    {
      render: (_, record) => (
        <ActionListButton items={actions} record={record} />
      ),
      width: 110,
      align: 'center',
    },
  ];

  const onSearch = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        setParams({ page: `1`, limit: `${limit}` });
        return;
      }

      if (value.length < 2) {
        message.error('Please enter at least 2 characters');
        return;
      }

      setParams({ search: value, page: `1`, limit: `${limit}` });
    },
    [limit, message, setParams]
  );

  return (
    <Space direction="vertical">
      <AppDrawer
        title={resource ? 'Update Merchant' : 'Add New Merchant'}
        drawerId="MerchantDrawer"
      >
        {resource ? <UpdateMerchant /> : <CreateMerchant />}
      </AppDrawer>
      <Flex justify="end" gap={16}>
        <Search
          style={{ width: 300 }}
          placeholder="Search merchants"
          onSearch={onSearch}
          allowClear
        />
        <Space.Compact>
          <Button
            type="primary"
            onClick={() => dispatch(openDrawer('MerchantDrawer'))}
          >
            <PlusSquareOutlined /> Add Merchant
          </Button>
        </Space.Compact>
        <Space.Compact>
          <NavLink to="/merchants/import">
            <Button type="primary">
              <ImportOutlined /> Import Wizard
            </Button>
          </NavLink>
        </Space.Compact>
        <Space.Compact>
          <Link
            href={`${API_BASEURL}/merchants/export?token=${accessToken}`}
            target="_blank"
          >
            <Button>
              <ExportOutlined /> Export Merchants
            </Button>
          </Link>
        </Space.Compact>
      </Flex>
      <DataTable
        columns={columns}
        data={merchants?.data}
        total={merchants?.meta.totalItems}
        isLoading={isFetching}
        rowSelection={{
          onSelect: (record, selected) => {},
          onSelectAll: (selected, selectedRows, changeRows) => {},
        }}
      />
    </Space>
  );
};
