import { Modal, TreeSelect, TreeSelectProps, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { axiosApi, useAuthUser } from '../../../core';
import { IPermission } from '../types';

const { Paragraph } = Typography;

type TProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId?: string;
};

export const PermissionsModal: React.FC<TProps> = ({
  open,
  setOpen,
  userId,
}) => {
  const [permissions, setPermissions] = useState<TreeSelectProps['treeData']>(
    []
  );
  const [selected, setSelected] = useState<string[]>([]);
  const { auth: { access_token } = {} } = useAuthUser();

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await axiosApi.get<{ data: IPermission[] }>('/auth/permissions', {
        access_token: access_token,
      });

      setPermissions(
        data.map((permission) => ({
          title: permission.attributes.title,
          value: permission.id,
          disabled: !permission.attributes.status,
        }))
      );
    })();

    return () => {};
  }, []);

  const onOk = useCallback(async () => {
    try {
      await axiosApi.put(
        '/auth/permissions',
        { data: { attributes: { permissions: selected, user_id: userId } } },
        { access_token: access_token }
      );

      setSelected([]);
      setOpen(false);
    } catch (err) {
    } finally {
    }
  }, [selected, userId]);

  return (
    <Modal
      open={open}
      title="Update Permissions"
      onCancel={() => {
        setSelected([]);
        setOpen(false);
      }}
      okText="Update"
      onOk={onOk}
    >
      <Paragraph>
        Update the user permissions by selecting the desired permissions from
        the list below.
      </Paragraph>
      <TreeSelect
        placeholder="Please select"
        style={{ width: '100%' }}
        treeCheckable
        treeNodeFilterProp="title"
        multiple
        value={selected}
        onChange={(value) => setSelected(value)}
        treeData={permissions}
      />
    </Modal>
  );
};
