import { TPaginationParams, TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';
import { IDevice, IUser, IUserAttributes } from '../types';

const userApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getUsers: build.query<TResponse<IUser[]>, TPaginationParams>({
      query: ({ page, limit, search }) => ({
        url: '/users',
        params: { page, limit, search },
      }),
    }),
    updateUser: build.mutation<
      void,
      Partial<IUserAttributes & { id: IUser['id'] }>
    >({
      query: ({ id, ...data }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body: {
          data: {
            id,
            type: 'User',
            attributes: data,
          },
        },
      }),
      invalidatesTags: ['Customers', 'Users'],
    }),
    getDevices: build.query<IDevice[], { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/devices`,
      }),
      transformResponse: (response: TResponse<IDevice[]>) => response.data,
      providesTags: ['Devices'],
    }),
  }),
});

export const { useGetUsersQuery, useUpdateUserMutation, useGetDevicesQuery } =
  userApi;
