import { Avatar, Card, Col, List, Row } from 'antd';
import { useEffect } from 'react';
import { Dayjs } from '../../../utils/date';
import { useGetUserActivitiesQuery } from '../store/dashboard.api';

type ActionType = 'created' | 'updated' | 'deleted' | 'default';

const actionColors = {
  created: '#87d068',
  updated: '#108ee9',
  deleted: '#f50',
  default: '#gray',
};

export const AdminDashboard: React.FC = () => {
  const { data: activities, refetch } = useGetUserActivitiesQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Row>
      <Col lg={12} sm={24} xs={24}>
        <Card
          title="Latest User Activities"
          style={{ width: '100%', marginTop: 16 }}
        >
          <List
            itemLayout="horizontal"
            dataSource={activities}
            renderItem={(activity) => {
              const action =
                activity.attributes.action.toLowerCase() as ActionType;
              const backgroundColor =
                actionColors[action] || actionColors.default;

              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar style={{ backgroundColor }}>
                        {activity.attributes.user.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    title={
                      <a href="javascript:void(0)">{`${
                        activity.attributes.resource
                      } ${activity.attributes.action.toLowerCase()}`}</a>
                    }
                    description={`User: ${
                      activity.attributes.user
                    } | Date: ${Dayjs.fromUTC(activity.attributes.date).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}`}
                  />
                </List.Item>
              );
            }}
          />
        </Card>
      </Col>
      <Col lg={12} sm={24} xs={24}></Col>
    </Row>
  );
};
