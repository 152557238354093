import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  TimePickerProps,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import markerIcon from '../../../assets/icons/global-map-marker.png';
import { ModalFormProps } from '../../../common';
import { FileInput } from '../../../core';
import { Dayjs } from '../../../utils/date';
import {
  useCreateOccasionMutation,
  useUpdateOccasionMutation,
} from '../store/occasion.api';
import {
  IOccasionAttributes,
  IOccasionFormValues,
  OccasionCategoryEnum,
  OccasionStatusEnum,
} from '../types';
import { LocationFinder } from './location_finder';

export const OccasionForm: React.FC<ModalFormProps<IOccasionAttributes>> = ({
  form,
  resource,
  onSuccess,
  onLoading,
}) => {
  const [createOccasion, { isSuccess: createdSuccessfully }] =
    useCreateOccasionMutation();
  const [updateOccasion, { isSuccess: updatedSuccessfully }] =
    useUpdateOccasionMutation();
  const [externalLinkVisible, setExternalLinkVisible] = useState(false);

  useEffect(() => {
    if (!resource) {
      return;
    }

    const startDate = Dayjs.fromUTC(resource.start_date);
    const endDate = Dayjs.fromUTC(resource.end_date);

    form!.setFieldsValue({
      status: resource.status,
      name: resource.name,
      start_date: startDate,
      end_date: endDate,
      start_time: startDate,
      end_time: endDate,
      description: resource.description,
      capacity: resource.capacity,
      categories: resource.categories,
      latitude: resource.latitude,
      longitude: resource.longitude,
      address: resource.address,
      for_family: resource.for_family,
      qr_code_title: resource.qr_code_title,
      qr_code_footer: resource.qr_code_footer,
      external_link: resource.external_link,
      confirmation_message: resource.confirmation_message,
    });

    if (resource.cover) {
      form!.setFieldsValue({
        cover: [{ uid: '-1', url: resource.cover, thumbnail: resource.cover }],
      });
    }

    if (resource.external_link) {
      setExternalLinkVisible(true);
    }

    if (resource.latitude && resource.longitude) {
    }
  }, [resource]);

  const onFinish = useCallback((values: IOccasionFormValues) => {
    onLoading?.();

    const {
      external_link,
      confirmation_message,
      qr_code_title,
      qr_code_footer,
      ...rest
    } = values;

    const properties = {
      ...rest,
      external_link: external_link || null,
      confirmation_message: confirmation_message || null,
      qr_code_title: !external_link ? qr_code_title : '--',
      qr_code_footer: !external_link ? qr_code_footer : '--',
    };

    if (!resource) {
      createOccasion(properties);
      return;
    }

    updateOccasion({
      ...properties,
      id: resource.key!,
    });
  }, []);

  useEffect(() => {
    if (createdSuccessfully || updatedSuccessfully) {
      onSuccess?.();
    }
  }, [createdSuccessfully, updatedSuccessfully]);

  const onChange: TimePickerProps['onChange'] = (time, timeString) => {
    console.log('Local time:', time.format()); // Show the local time
    console.log('UTC time:', dayjs(time).local().utc().format()); // Convert to UTC and show the result
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true }]}
            wrapperCol={{ span: 12 }}
            initialValue={OccasionStatusEnum.DRAFT}
          >
            <Select
              options={Object.values(OccasionStatusEnum).map((status) => ({
                label:
                  status.charAt(0).toLocaleUpperCase() +
                  status.slice(1).toLocaleLowerCase(),
                value: status,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="for_family" label="For Family" initialValue={false}>
            <Switch />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="External Checkin">
            <Switch
              value={externalLinkVisible}
              onChange={() => setExternalLinkVisible(!externalLinkVisible)}
            />
          </Form.Item>
        </Col>
      </Row>
      {externalLinkVisible && (
        <>
          <Form.Item
            name={'external_link'}
            label="External Checkin & Payment Link"
            rules={[
              { required: true },
              {
                validator: (rule, value) => {
                  if (
                    !value ||
                    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
                      value
                    )
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Please enter a valid URL.'));
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="confirmation_message"
            label="Confirmation Message"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </>
      )}
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      {!externalLinkVisible && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="QR Code Card Title"
              name="qr_code_title"
              rules={[{ required: true, min: 10, max: 70 }]}
            >
              <Input.TextArea
                placeholder="Enter the title of the QR Code Card"
                rows={4}
                style={{ resize: 'none' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="QR Code Card Footer"
              name="qr_code_footer"
              rules={[{ required: true, min: 10, max: 100 }]}
            >
              <Input.TextArea
                placeholder="Enter the footer of the QR Code Card"
                rows={4}
                style={{ resize: 'none' }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <FileInput
        name="cover"
        label="Cover"
        onFileUploaded={(info) => console.log(info)}
      />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Start Date"
            name="start_date"
            rules={[{ required: true }]}
          >
            <DatePicker format={'MMM DD, YYYY'} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Start Time"
            name="start_time"
            rules={[{ required: true }]}
          >
            <TimePicker use12Hours format={'hh:mm a'} onChange={onChange} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="End Date"
            name="end_date"
            rules={[{ required: true }]}
          >
            <DatePicker format={'MMM DD, YYYY'} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="End Time"
            name="end_time"
            rules={[{ required: true }]}
          >
            <TimePicker use12Hours format={'hh:mm a'} onChange={onChange} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true }]}
      >
        <Input.TextArea rows={5} style={{ resize: 'none' }} />
      </Form.Item>
      <Row>
        <Col span={6}>
          <Form.Item
            label="Capacity"
            name="capacity"
            rules={[{ required: true }]}
          >
            <InputNumber min={5} step={1} />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            label="Categories"
            name="categories"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              options={Object.values(OccasionCategoryEnum).map((category) => ({
                label:
                  category.charAt(0).toLocaleUpperCase() +
                  category.slice(1).toLocaleLowerCase().replace('_', ' '),
                value: category,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Address" name="address" rules={[{ required: true }]}>
        <Input.TextArea style={{ resize: 'none' }} />
      </Form.Item>
      <Space direction="vertical" style={{ width: '100%' }}>
        <LocationFinder
          location={
            resource &&
            new google.maps.LatLng({
              lat: resource.latitude,
              lng: resource.longitude,
            })
          }
          onLocationChange={(location) => {
            form!.setFieldValue('latitude', location.lat());
            form!.setFieldValue('longitude', location.lng());
          }}
          marker={markerIcon}
        />
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Latitude"
              name="latitude"
              rules={[{ required: true }]}
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Longitude"
              name="longitude"
              rules={[{ required: true }]}
            >
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};
