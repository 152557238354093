import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { MerchantList } from './views/list';
import { MerchantView } from './views/view';

export const merchantRoutes: IAppRoute[] = [
  {
    path: '/merchants',
    component: MerchantList,
    roles: [UserRoleEnum.ADMIN],
  },
  {
    path: '/merchants/:merchantId',
    component: MerchantView,
    roles: [UserRoleEnum.ADMIN],
  },
];
