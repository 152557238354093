export enum MerchantStatusEnum {
  DRAFT = 'draft',
  PENDING = 'pending',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export enum MerchantTypeEnum {
  IN_STORE = 'in_store',
  ONLINE = 'online',
  SOCIAL_MEDIA = 'social_media',
}

export enum LinkTypeEnum {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  WEBSITE = 'website',
  TIKTOK = 'tiktok',
  YOUTUBE = 'youtube',
  PHONE = 'phone',
  OTHER = 'other',
}
