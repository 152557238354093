import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Typography } from 'antd';
import Lottie from 'lottie-react';
import React, { useEffect } from 'react';
import savingAnimation from '../../assets/animations/yUrwxh8VjP.json';
import '../../assets/css/Login.css';
import Background from '../../assets/images/wave_10s_1440px.svg';

import { authenticated, useAppDispatch } from '../../core';
import { useSignInMutation } from '../store/auth.api';
import { TSignInArgs } from '../types';

const { Link } = Typography;

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [signIn, { data, isLoading }] = useSignInMutation();
  const [form] = Form.useForm();

  const onFinish = (values: TSignInArgs) => {
    signIn(values);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    form.resetFields();
    setTimeout(() => {
      dispatch(authenticated(data));
    }, 100);
  }, [data, dispatch, form]);

  return (
    <div className="login-container">
      <div className="svg-container">
        <img src={Background} alt="Animated Background" className="svg-bg" />
      </div>
      <div className="login-content">
        <Card>
          <Lottie style={{ width: 250 }} animationData={savingAnimation} />
          <Form
            form={form}
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Please input your Username!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Link href="/reset-password" className="login-form-forgot">
                Reset password
              </Link>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={isLoading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
