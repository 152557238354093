import dayjs, { ConfigType } from 'dayjs';
import { DEFAULT_TIMEZONE } from '../common';

export class Dayjs {
  static fromUTC(config?: ConfigType, format?: string, strict?: boolean) {
    return dayjs.utc(config, format, strict).tz(DEFAULT_TIMEZONE);
  }

  static toUTC(config?: ConfigType, format?: string, strict?: boolean) {
    return dayjs(config, format, strict).local().utc();
  }
}
