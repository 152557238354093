import {
  EditOutlined,
  PhoneOutlined,
  SettingOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Card, Space, Typography } from 'antd';
import { find } from 'lodash';
import { ICustomer, ISubscription } from '../types';
import { SubscriptionBadge } from './SubscriptionBadge';

const { Meta } = Card;
const { Text } = Typography;

type TProps = {
  isLoading: boolean;
  customer?: ICustomer;
  subscriptions?: ISubscription[];
};

export const ProfileCard: React.FC<TProps> = ({
  isLoading,
  customer,
  subscriptions,
}) => {
  const subscription = find(subscriptions, { attributes: { status: true } });
  
  return (
    <Card
      style={{ width: 400 }}
      size="small"
      actions={[<EditOutlined key="edit" />, <SettingOutlined key="setting" />]}
      loading={isLoading}
    >
      <Badge.Ribbon
        text={customer?.attributes?.user.status ? 'Active' : 'Inactive'}
        color={customer?.attributes?.user.status ? 'green' : 'red'}
      />
      <Meta
        title={customer?.attributes.user.full_name}
        description={
          <Space direction="vertical">
            <Text>
              <PhoneOutlined /> {customer?.attributes.user.phone}
            </Text>
            <Text type={customer?.attributes.has_wallet ? 'success' : 'danger'}>
              <WalletOutlined />{' '}
              {customer?.attributes.has_wallet
                ? 'Active wallet'
                : 'No active wallet'}
            </Text>
            <Space>
            <SubscriptionBadge subscription={subscription?.attributes} /><Text>{subscription && `Expires At: ${subscription.attributes.expires_at}`}</Text>
            </Space>
          </Space>
        }
        avatar={<Avatar src={customer?.attributes?.user.avatar} />}
      />
    </Card>
  );
};
