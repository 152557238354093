import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Divider, Layout } from 'antd';
import { useMemo } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { appRoutes, useAuthUser } from '../core';
import { UserRoleEnum } from '../features/users/enums';

const { Content } = Layout;

export const ContentLayout = () => {
  const breadcrumbs = useBreadcrumbs(appRoutes);
  const { user: { attributes: { roles = [] } = {} } = {} } = useAuthUser();
  const isBranch = useMemo(() => {
    return roles.find((role) => role.role === UserRoleEnum.BRANCH);
  }, [roles]);

  const items = breadcrumbs.map(({ breadcrumb, match, key }) => {
    const title = (
      <Link to={key}>{key === '/' ? <HomeOutlined /> : breadcrumb}</Link>
    );

    return {
      key,
      title,
    };
  });

  return (
    <Content style={{ background: '#fff', padding: 24, paddingTop: 55 }}>
      <Breadcrumb items={items} />
      <Divider />
      <Routes>
        {appRoutes
          .filter((item) => roles.some((e) => new Set(item.roles).has(e.role)))
          .map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                Component={route.component}
              />
            );
          })}
      </Routes>
    </Content>
  );
};
