import { Space, Tag, Typography } from 'antd';

const { Text } = Typography;

type TProps = {
  username: string;
  password: string;
};

export const PasswordModal: React.FC<TProps> = ({ username, password }) => {
  return (
    <Space direction="vertical">
      <Space>
        <Text>Username:</Text>
        <Tag color="default">{username}</Tag>
      </Space>
      <Space>
        <Text>New Password:</Text>
        <Tag color="default">{password}</Tag>
      </Space>
    </Space>
  );
};
