import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { CategoryList } from './views/list';

export const categoryRoutes: IAppRoute[] = [
  {
    path: '/categories',
    component: CategoryList,
    roles: [UserRoleEnum.ADMIN],
  },
  {
    path: '/categories/:categoryId',
    roles: [UserRoleEnum.ADMIN],
  },
];
