import { Tag } from 'antd';
import { RedeemStatusEnum } from '../enums';

export const RedeemStatus: React.FC<{ status: string }> = ({ status }) => {
  let color = 'greenblue';

  switch (status) {
    case RedeemStatusEnum.CREATED:
      color = 'blue';
      break;
    case RedeemStatusEnum.INITIATED:
      color = 'orange';
      break;
    case RedeemStatusEnum.REDEEMED:
      color = 'green';
      break;
    case RedeemStatusEnum.FAILED:
      color = 'red';
      break;
  }

  return <Tag color={color}>{status.charAt(0).toUpperCase() + status.slice(1)}</Tag>;
};
