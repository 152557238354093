import { Typography } from 'antd';
import { useAuthUser } from '../../../core';
import { UserRoleEnum } from '../../users/enums';
import { AdminDashboard } from '../components/admin_dashboard';
import { BrnachDashboard } from '../components/branch_dashboard';
import { MerchantDashboard } from '../components/merchant_dashboard';

const { Text } = Typography;

export const Dashboard = () => {
  const { user: { attributes: { roles = [] } = {} } = {} } = useAuthUser();

  const role = roles[0].role as UserRoleEnum;

  if (role === UserRoleEnum.ADMIN) {
    return <AdminDashboard />;
  }

  if (role === UserRoleEnum.MERCHANT) {
    return <MerchantDashboard />;
  }

  if (role === UserRoleEnum.BRANCH) {
    return <BrnachDashboard />;
  }

  return <Text type="secondary">Please wait while we load your dashboard</Text>;
};
