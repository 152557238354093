import {
  AlertOutlined,
  CalendarOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  ProductOutlined,
  TruckOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { FaUserTie } from 'react-icons/fa';
import { RiGalleryLine, RiUserSearchLine } from 'react-icons/ri';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthUser } from '../core';
import { UserRoleEnum } from '../features/users/enums';
import { RahetBallyLogo } from './components/RahetBallyLogo';

interface IMenuActionItem {
  key: string;
  label: string;
  to: string;
  icon: JSX.Element;
  roles: string[];
}

export const SiderLayout = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState<string>('');
  const { user: { attributes: { roles = [] } = {} } = {} } = useAuthUser();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const navigationItems: IMenuActionItem[] = useMemo(() => {
    return [
      {
        key: 'dashboard',
        to: '/',
        label: 'Dashboard',
        icon: <DashboardOutlined />,
        roles: [UserRoleEnum.ADMIN, UserRoleEnum.MERCHANT, UserRoleEnum.BRANCH],
      },
      {
        key: 'redeem',
        to: '/redeem',
        label: 'Redeem',
        icon: <CreditCardOutlined />,
        roles: [],
      },
      {
        key: 'reports',
        to: '/reports',
        label: 'Reports',
        icon: <CreditCardOutlined />,
        roles: [...Object.values(UserRoleEnum)],
      },
      {
        key: 'customers',
        to: '/customers',
        label: 'Customers',
        icon: <RiUserSearchLine />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'merchants',
        to: '/merchants',
        label: 'Merchants',
        icon: <TruckOutlined />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'staff',
        to: '/staff',
        label: 'Staff',
        icon: <FaUserTie />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'categories',
        to: '/categories',
        label: 'Categories',
        icon: <ProductOutlined />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'users',
        to: '/users',
        label: 'Users',
        icon: <UserOutlined />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'banners',
        to: '/banners',
        label: 'App Banners',
        icon: <RiGalleryLine />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'notifications',
        to: '/notifications',
        label: 'Notifications',
        icon: <AlertOutlined />,
        roles: [UserRoleEnum.ADMIN],
      },
      {
        key: 'events_managment',
        to: '/events',
        label: 'Event Managment',
        icon: <CalendarOutlined />,
        roles: [UserRoleEnum.ADMIN],
      },
    ];
  }, []);

  useEffect(() => {
    const matchedItem = navigationItems.find(
      (item) => item.to === location.pathname
    );

    if (matchedItem) {
      setSelectedPage(matchedItem.key);
    }
  }, [location.pathname, navigationItems]);

  return (
    <Sider
      breakpoint="md"
      collapsedWidth="0"
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
      }}
      style={{
        position: 'fixed',
        height: '100vh',
        left: 0,
        top: 0,
        zIndex: 1000,
      }}
    >
      <RahetBallyLogo />
      <Menu
        mode="inline"
        theme="dark"
        style={{ height: '100%', borderRight: 0 }}
        selectedKeys={[selectedPage]}
      >
        {navigationItems
          .filter((item) => roles.some((e) => new Set(item.roles).has(e.role)))
          .map((item) => {
            return (
              <Menu.Item
                key={item.key}
                onClick={() => {
                  if (isMobile) {
                    setCollapsed(true);
                  }
                  setSelectedPage(item.key);
                  navigate(item.to);
                }}
                icon={item.icon}
              >
                {item.label}
              </Menu.Item>
            );
          })}
      </Menu>
    </Sider>
  );
};
