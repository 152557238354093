import { PlusOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  TreeSelect,
  Typography,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_UPLOAD_BASEURL } from '../../../../../common/constants';
import {
  AppForm,
  closeDrawer,
  useAppDispatch,
  useAuthUser,
  useDrawer,
  useForm,
} from '../../../../../core';
import { useGetPackagesQuery } from '../../../../subscriptions/store/package.api';
import { FormUtils } from '../../../utils/FormUtils';
import { useGetBranchesQuery } from '../../branches/store/branch.api';
import { OfferComponentTypeEnum } from '../enums';

const { TextArea } = Input;
const { Text, Title } = Typography;

type TProps = {
  isSubmitted: boolean;
  onFinish: (values: any) => void;
};

export const OfferForm: React.FC<TProps> = ({ isSubmitted, onFinish }) => {
  const { form } = useForm({ drawerId: 'OfferDrawer' });
  const { merchantId = '' } = useParams<string>();
  const { data: branches } = useGetBranchesQuery({ merchantId });
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  const [isFeatured, setIsFeatured] = useState<boolean>(false);
  const [isFlashDeal, setIsFlashDeal] = useState<boolean>(false);
  const { data: packages, isLoading } = useGetPackagesQuery();
  const dispatch = useAppDispatch();
  const { resource } = useDrawer();
  const [limitedRedeems, setLimitedRedeems] = useState<boolean>(false);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    dispatch(closeDrawer());
  }, [isSubmitted, dispatch]);

  useEffect(() => {
    if (!resource || resource.type !== 'Offer' || isLoading) {
      return;
    }

    console.log(resource);

    const {
      start_date,
      end_date,
      featured_start_date,
      featured_end_date,
      flash_deal_start_date,
      flash_deal_end_date,
      packages,
      components,
      promo_code,
      ...reset
    } = resource.attributes;

    form.setFieldsValue({
      ...reset,
      start_date: dayjs(start_date),
      end_date: dayjs(end_date),
      featured_start_date: featured_start_date
        ? dayjs(featured_start_date)
        : undefined,
      featured_end_date: featured_end_date
        ? dayjs(featured_end_date)
        : undefined,
      flash_deal_start_date: flash_deal_start_date
        ? dayjs(flash_deal_start_date)
        : undefined,
      flash_deal_end_date: flash_deal_end_date
        ? dayjs(flash_deal_end_date)
        : undefined,
      packages: packages.map((item: { id: string; name: string }) => item.id),
      cover: [
        {
          uid: '-1',
          name: resource.attributes.cover,
          thumbnail: resource.attributes.cover,
          url: resource.attributes.cover,
        },
      ],
      offer_type: components[0]?.type,
      offer_value: components[0]?.value,
      max_amount: components[0]?.max_amount,
      min_cart_amount: components[0]?.min_cart_amount,
      branches: resource.attributes.branches?.map(
        (item: { id: string }) => item.id
      ),
      ...(promo_code && {
        promo_code: promo_code.code,
        promo_link: promo_code.link,
      }),
      ...(components[0]?.limited_redeems && {
        limited_redeems: components[0]?.limited_redeems,
        limited_redeems_type: components[0]?.limited_redeems_type,
        limited_redeems_count: components[0]?.limited_redeems_count,
      }),
    });

    setIsFeatured(resource.attributes.is_featured);
    setIsFlashDeal(resource.attributes.is_flash_deal);
    setLimitedRedeems(components[0]?.limited_redeems);
  }, [form, isLoading, resource]);

  return (
    <AppForm form={form} labelCol={{ span: 7 }} onFinish={onFinish}>
      <Form.Item name={'status'} label="Status" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name={'branches'} label={'Branches'}>
        <TreeSelect
          showSearch
          treeNodeFilterProp="label"
          treeCheckable
          maxTagCount={2}
          treeData={branches?.map((branch) => ({
            title: branch.attributes.name,
            value: branch.id,
            key: branch.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={'name'}
        label="Name"
        required
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'description'}
        label="Description"
        required
        rules={[{ required: true }]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item
        name={'packages'}
        label="Packages"
        required
        rules={[{ required: true }]}
      >
        <TreeSelect
          showSearch
          treeNodeFilterProp="title"
          treeCheckable
          maxTagCount={1}
          placeholder="Select Packages"
          treeData={packages?.map((subscriptionPackage) => ({
            value: subscriptionPackage.id,
            title: subscriptionPackage.attributes.name,
          }))}
        />
      </Form.Item>
      <Form.Item
        name={'cover'}
        label="Cover"
        valuePropName="fileList"
        getValueFromEvent={FormUtils().normFile}
        required
        rules={[{ required: true }]}
      >
        <Upload
          action={API_UPLOAD_BASEURL}
          listType="picture-card"
          accept="image/*"
          maxCount={1}
          headers={{
            Authorization: `Bearer ${accessToken}`,
          }}
        >
          <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={'terms_and_conditions'}
        label="Terms and Condition"
        required
        rules={[{ required: true }]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item
        name={'start_date'}
        label="Start Date"
        required
        rules={[{ required: true }]}
      >
        <DatePicker format={'YYYY-MM-DD'} showTime={false} />
      </Form.Item>
      <Form.Item
        name={'end_date'}
        label="End Date"
        required
        rules={[{ required: true }]}
      >
        <DatePicker format={'YYYY-MM-DD'} showTime={false} />
      </Form.Item>
      <Form.Item name={'is_featured'} label="Featured" valuePropName="checked">
        <Switch onChange={(value: boolean) => setIsFeatured(value)} />
      </Form.Item>
      {isFeatured && (
        <>
          <Form.Item
            name={'featured_start_date'}
            label="Start Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker format={'YYYY-MM-DD'} showTime={false} />
          </Form.Item>
          <Form.Item
            name={'featured_end_date'}
            label="End Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker format={'YYYY-MM-DD'} showTime={false} />
          </Form.Item>
        </>
      )}
      <Form.Item
        name={'is_flash_deal'}
        label="Flash Deal"
        valuePropName="checked"
      >
        <Switch onChange={(value: boolean) => setIsFlashDeal(value)} />
      </Form.Item>
      {isFlashDeal && (
        <>
          <Form.Item
            name={'flash_deal_start_date'}
            label="Start Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name={'flash_deal_end_date'}
            label="End Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
        </>
      )}
      <Divider />
      <Form.Item label={'Min Amount (EGP)'} name={'min_cart_amount'}>
        <Input placeholder="0.00 EGP" type="number" min={0} />
      </Form.Item>
      <Form.Item
        label="Offer Type"
        name="offer_type"
        required
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select offer type"
          options={[
            {
              label: 'Fixed Amount',
              value: OfferComponentTypeEnum.FIXED_AMOUNT,
            },
            {
              label: 'Fixed Percentage',
              value: OfferComponentTypeEnum.FIXED_PERCENTAGE,
            },
            {
              label: 'Cashback Fixed Percentage',
              value: OfferComponentTypeEnum.CASHBACK_FIXED_PERCENTAGE,
            },
            {
              label: 'Buy One Get One',
              value: OfferComponentTypeEnum.BOGO,
            },
            {
              label: 'Special',
              value: OfferComponentTypeEnum.SPECIAL,
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={'Offer Value'}
        name={'offer_value'}
        required
        rules={[{ required: true }]}
      >
        <Input placeholder="0" type={'number'} min={0} />
      </Form.Item>
      <Form.Item label={'Max Discount (EGP)'} name={'max_amount'}>
        <Input placeholder="0.00 EGP" type="number" />
      </Form.Item>
      <Form.Item name="limited_redeems" label="Limited Redeems">
        <Switch
          onChange={(value: boolean) => {
            setLimitedRedeems(value);

            if (value === false) {
              form.setFieldsValue({
                limited_redeems_count: undefined,
                limited_redeems_type: undefined,
              });
            }
          }}
        />
      </Form.Item>
      {limitedRedeems && (
        <>
          <Form.Item
            label="Limitation Type"
            name="limited_redeems_type"
            required
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  label: 'Per User',
                  value: 'per_user',
                },
                {
                  label: 'Per Offer',
                  value: 'per_offer',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="limited_redeems_count"
            label="Redeem Counts"
            required
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>
        </>
      )}
      <Divider />
      <Form.Item label="Promo Code" name="promo_code">
        <Input />
      </Form.Item>
      <Form.Item label="Promo Link" name="promo_link">
        <Input />
      </Form.Item>
    </AppForm>
  );
};
