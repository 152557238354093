import { TableProps } from 'antd';

import { DataTable } from '../../../core';
import { Dayjs } from '../../../utils/date';
import { useGetDevicesQuery } from '../../users/store/user.api';

type TProps = {
  userId: string;
};

export const Devices: React.FC<TProps> = ({ userId }) => {
  const { data: devices } = useGetDevicesQuery({ id: userId });

  const columns: TableProps['columns'] = [
    {
      title: 'Agent',
      dataIndex: 'agent',
    },
    {
      title: 'Session Date',
      dataIndex: 'created_at',
      render: (date) => Dayjs.fromUTC(date).format('MMM D, YYYY h:mm A'),
    },
  ];

  return <DataTable columns={columns} data={devices || []} />;
};
