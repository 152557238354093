import { SearchOutlined } from '@ant-design/icons';
import { App, Button, Col, Divider, Form, InputNumber, Row, Space } from 'antd';
import { useState } from 'react';
import { GrPowerReset } from 'react-icons/gr';
import CustomOTPInput from './custom_otp';
import { RedeemDetails } from './redeem_details';

export const Redeem: React.FC = () => {
  // const { auth: { access_token: accessToken } = {}, user } = useAuthUser();
  // const { message } = App.useApp();
  // const [redeems, setRedeems] = useState<{ id: String; title: string }[]>([]);
  // const [redeemOffer, { isLoading, isSuccess }] = useRedeemOfferMutation();
  const [form] = Form.useForm();
  const [redeemData, setRedeemData] = useState<{
    redeem_code: string;
    cart_total: number;
  }>();
  const { message } = App.useApp();

  // useEffect(() => {
  //   const socket = io('http://localhost:3000', {
  //     extraHeaders: {
  //       authorization: `Bearer ${accessToken}`,
  //       ...(user?.id && { userId: user.id }),
  //     },
  //   });

  //   let isNavigated = false;

  //   console.log('called');

  //   message.loading('Connecting...', 0);

  //   socket.on('connect', () => {
  //     message.destroy();

  //     setTimeout(() => {
  //       message.success('Connected');
  //     }, 500);
  //   });

  //   socket.on('disconnect', () => {
  //     if (!isNavigated) {
  //       message.error('Connection lost');

  //       setTimeout(() => {
  //         message.loading('Connecting...', 0);
  //       }, 2500);
  //     }
  //   });

  //   socket.on('error', (err) => {
  //     message.error(err.message);
  //     message.destroy();
  //   });

  //   socket.on('redeem', (message) => {
  //     console.log(message);

  //     const redeemId = message.redeem.id;
  //     const found = find(redeems, { id: redeemId });

  //     if (found) {
  //       return;
  //     }

  //     console.log(message.redeem.id);

  //     setRedeems((prev) => [
  //       ...prev,
  //       {
  //         id: redeemId,
  //         title: message.redeem.user.full_name,
  //       },
  //     ]);
  //   });

  //   return () => {
  //     isNavigated = true;
  //     socket.close();
  //     message.destroy();
  //   };
  // }, [accessToken, message, redeems, user?.id]);

  return (
    <Row>
      <Col lg={24} sm={24} xs={24} md={24}>
        <Form
          form={form}
          onFinish={(values) => {
            setRedeemData(values);
          }}
          name="redeem"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
        >
          <CustomOTPInput form={form} />

          <Form.Item
            label="Cart Total (Before Discount)"
            wrapperCol={{ offset: 0, span: 24 }}
            name="cart_total"
            rules={[{ required: true }]}
            required
          >
            <InputNumber
              type="number"
              addonAfter="EGP"
              min={1}
              inputMode="numeric"
              pattern="[0-9]*"
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              lg: { offset: 10 },
              md: { offset: 10 },
              sm: { offset: 10 },
            }}
          >
            <Space.Compact>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Find & Redeem
              </Button>
              <Button
                type="dashed"
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                  setRedeemData(undefined);
                }}
                icon={<GrPowerReset />}
              />
            </Space.Compact>
          </Form.Item>
        </Form>
      </Col>
      <Col lg={24} sm={24} xs={24} md={24}>
        {redeemData && (
          <>
            <Divider />
            <RedeemDetails
              redeem_code={redeemData.redeem_code}
              cart_total={redeemData.cart_total}
              success={() => {
                form.resetFields();
                setRedeemData(undefined);
                message.success('Redeem Successful');
              }}
            />
          </>
        )}
      </Col>
    </Row>
  );
};
