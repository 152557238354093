import { Button, Drawer, Space } from 'antd';
import { useCallback } from 'react';
import { TDrawers } from '../../common/types';
import { useDrawer } from '../hooks/useAppDrawer';
import { closeDrawer, drawerSubmit } from '../store/drawer.slice';
import { useAppDispatch } from '../store/store';

type TDrawerProps = {
  children: React.ReactNode;
  title: string;
  drawerId: TDrawers;
};

export const AppDrawer: React.FC<TDrawerProps> = ({
  children,
  title,
  drawerId,
}) => {
  const { open, drawerId: openedDrawer, isSubmitting, resource } = useDrawer();
  const dispatch = useAppDispatch();

  const handelClose = useCallback(() => {
    dispatch(closeDrawer());
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(drawerSubmit(true));
  }, [dispatch]);

  return (
    <Drawer
      title={title}
      open={open && openedDrawer === drawerId}
      placement="right"
      width={600}
      styles={{ footer: { textAlign: 'right' } }}
      onClose={handelClose}
      footer={
        <Space>
          <Button disabled={isSubmitting} type="default" onClick={handelClose}>
            Close
          </Button>
          <Button loading={isSubmitting} type="primary" onClick={handleSubmit}>
            {resource ? 'Update' : 'Submit'}
          </Button>
        </Space>
      }
    >
      {children}
    </Drawer>
  );
};
