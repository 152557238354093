import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAuth, IAuthState } from '../../auth/types';
import { REFRESH_TOKEN_KEY } from '../../common/constants';
import { IUser } from '../../features/users/types';

const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

const initialState: IAuthState = {
  isLoggedIn: false,
  ...(refreshToken
    ? {
        auth: {
          refresh_token: refreshToken,
        },
      }
    : {}),
};

const authSlice = createSlice({
  name: 'superAppAuth',
  initialState,
  reducers: {
    authenticated: (state, action: PayloadAction<IAuth>) => {
      state.auth = action.payload;
      state.isLoggedIn = true;

      localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refresh_token);
    },
    loginRejected: (state) => {
      state.user = undefined;
      state.auth = undefined;
      state.isLoggedIn = false;
    },
    signOut: (state) => {
      state.user = undefined;
      state.auth = undefined;
      state.isLoggedIn = false;

      localStorage.removeItem(REFRESH_TOKEN_KEY);
    },
    fetchUser: (state, action: PayloadAction<IUser & { meta: any }>) => {
      state.user = action.payload;
    },
  },
});

export const { authenticated, loginRejected, signOut, fetchUser } =
  authSlice.actions;
export default authSlice.reducer;
