import { BellOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Badge, Layout, Space, Typography, theme } from 'antd';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router-dom';
import { signOut, useAppDispatch, useAuthUser } from '../core';

const { Header } = Layout;
const { Text } = Typography;

export const HeaderLayout = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthUser();
  const { token } = theme.useToken();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(signOut());

    navigate('/');
  };

  return (
    <Header
      style={{
        padding: 0,
        background: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '24px',
      }}
    >
      <div style={{ paddingLeft: '24px' }}></div>
      <div>
        <Badge>
          <Space direction="horizontal" size={24}>
            <Link>
              <BellOutlined style={{ fontSize: '24px' }} />
            </Link>
            <Link onClick={handleSignOut}>
              <PoweroffOutlined style={{ fontSize: '24px' }} />
            </Link>
            <Text style={{ fontSize: '12px', color: token.colorPrimary }}>
              {`Welcome! ${user?.attributes.full_name}`}
            </Text>
          </Space>
        </Badge>
      </div>
    </Header>
  );
};
