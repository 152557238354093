import { useCallback } from 'react';
import { TFileInfo } from '../../../../../common/types';

import { setLinks, useAppDispatch, useImportWizard } from '../../../../../core';
import { FileUtils } from '../../../utils/FileUtils';
import { useUpdateTaskMutation } from '../store/import.api';
import { LinksImportTable } from './LinksImportTable';
import { UploadButton } from './UploadButton';

export const LinkStep: React.FC = () => {
  const { taskId, links } = useImportWizard();
  const dispatch = useAppDispatch();
  const [updateTask] = useUpdateTaskMutation();

  const onFileUploaded = useCallback(
    (info: TFileInfo) => {
      if (FileUtils().isFileUploaded(info) || !taskId) {
        return;
      }

      const file = info?.fileList[0].originFileObj as File;
      const filename = FileUtils().getFileName(info.fileList);

      FileUtils().readExcelFile(file, (data) => {
        dispatch(
          setLinks(
            data.map((link: any) => ({
              ...link,
              key: `${link.title}-${link.type}`,
            }))
          )
        );

        updateTask({ taskId, data: { branches_file: filename } });
      });
    },
    [dispatch, taskId, updateTask]
  );

  return (
    <>
      {links && taskId ? (
        <LinksImportTable taskId={taskId} links={links} />
      ) : (
        <UploadButton
          onFileUploaded={onFileUploaded}
          label="Select Links File"
        />
      )}
    </>
  );
};
