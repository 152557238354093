import { Table, TableProps } from 'antd';

type TProps = {
  taskId: string;
  branches: any;
};

export const BranchesImportTable: React.FC<TProps> = ({ taskId, branches }) => {

  const columns: TableProps['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      ellipsis: true,
    },
    {
      title: 'Working Hours',
      dataIndex: 'working_hours',
    },
    {
      title: 'Map Link',
      dataIndex: 'location',
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      ellipsis: true,
    },
    {
      title: 'District',
      dataIndex: 'district',
    },
  ];

  return (
    <Table pagination={false} columns={columns} dataSource={branches || []} />
  );
};
