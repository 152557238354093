import { EGP } from '@dinero.js/currencies';
import { Currency, dinero, toDecimal } from 'dinero.js';

type IntlFormatProps = {
  amount: number;
  currency?: Currency<number>;
  locale?: string;
  options?: any;
};

export const CurrencyUtils = () => ({
  intlFormat: ({
    amount,
    currency = EGP,
    locale = 'en-EG',
    options = {},
  }: IntlFormatProps) => {
    const dmoney = dinero({ amount, currency });

    const transformer = ({
      value,
      currency,
    }: {
      value: string;
      currency: Currency<unknown>;
    }) => {
      return Number(value).toLocaleString(locale, {
        ...options,
        style: 'currency',
        currency: currency.code,
      });
    };

    return toDecimal(dmoney, transformer);
  },
});
