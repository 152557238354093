type UrlProps = {
  baseUrl: string;
  pathSegments?: string[];
  queryParams?: Record<string, any>;
};

export class Url {
  static buildUrl(props: UrlProps) {
    const { baseUrl, pathSegments = [], queryParams = {} } = props;

    console.log(baseUrl);
    console.log(pathSegments);
    console.log(queryParams);

    const url = new URL(baseUrl);

    pathSegments.forEach((segment) => {
      url.pathname += `/${segment}`;
    });

    Object.keys(queryParams || {}).forEach((key) => {
      url.searchParams.append(key, queryParams[key]);
    });

    return url.toString();
  }
}
