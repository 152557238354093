import { TPaginationParams, TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';
import { IRedeem } from '../../merchants/features/offers/types';
import {
  ICustomer,
  ICustomerStatistic,
  IPayment,
  ISubscription,
  TGetCustomerArgs,
} from '../types';

export const customersApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getCustomer: builder.query<ICustomer, TGetCustomerArgs>({
      query: ({ id }) => ({
        url: `/customers/${id}`,
      }),
      transformResponse: (response: TResponse<ICustomer>) => response.data,
    }),
    getSubscriptions: builder.query<ISubscription[], { id: string }>({
      query: ({ id }) => ({
        url: `/customers/${id}/subscriptions`,
      }),
      transformResponse: (response: TResponse<ISubscription[]>) =>
        response.data,
    }),
    getPayments: builder.query<IPayment[], { id: string }>({
      query: ({ id }) => ({
        url: `/customers/${id}/payments`,
      }),
      transformResponse: (response: TResponse<IPayment[]>) => response.data,
    }),
    getRedeems: builder.query<IRedeem[], { id: string }>({
      query: ({ id }) => ({
        url: `/customers/${id}/redeems`,
      }),
      transformResponse: (response: TResponse<IRedeem[]>) => response.data,
    }),
    getStatistics: builder.query<ICustomerStatistic, { id: string }>({
      query: ({ id }) => ({
        url: `/customers/${id}/statistics`,
      }),
      transformResponse: (response: TResponse<ICustomerStatistic>) =>
        response.data,
    }),
    getCustomers: builder.query<TResponse<ICustomer[]>, TPaginationParams>({
      query: ({ page, limit, search }) => ({
        url: '/customers',
        params: { page, limit, search },
      }),
      providesTags: ['Customers'],
    }),
    createCustomer: builder.mutation<void, Partial<ICustomer>>({
      query: (data) => ({
        url: '/customers',
        method: 'POST',
        body: data,
      }),
    }),
    updateCustomer: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/customers/${id}`,
        method: 'PATCH',
        body: {
          data: {
            id,
            type: 'Customer',
            attributes: data,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useGetSubscriptionsQuery,
  useGetPaymentsQuery,
  useGetRedeemsQuery,
  useGetStatisticsQuery,
} = customersApi;
