import { Layout } from 'antd';

export const FooterLayout = () => {
  const { Footer } = Layout;
  return (
    <Footer style={{ textAlign: 'center' }}>
      Super App 1.0.0 ©{new Date().getFullYear()} Rahet Bally
    </Footer>
  );
};
