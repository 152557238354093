import { Tag } from 'antd';
import { MerchantStatusEnum } from '../enums';

type TProps = {
  status: MerchantStatusEnum;
};

export const MerchantStatus = ({ status }: TProps) => {
  const colors = {
    [MerchantStatusEnum.DRAFT]: 'cyan',
    [MerchantStatusEnum.PENDING]: 'orange',
    [MerchantStatusEnum.PUBLISHED]: 'green',
    [MerchantStatusEnum.ARCHIVED]: 'default',
  };

  return (
    <Tag color={colors[status]}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </Tag>
  );
};
