import {
  App,
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { TAutoCompleteItem } from '../../../common/types';
import { AppForm, axiosApi, useAuthUser } from '../../../core';
import { IMerchant } from '../../merchants/types';
import { useGetPackagesQuery } from '../../subscriptions/store/package.api';
import { IUser } from '../../users/types';
import { OfferSelect } from '../components/offer_select';
import { useSendNotificationMutation } from '../store/notification.api';
import { TNotificationAction } from '../types';

const { Title } = Typography;
const { Search } = Input;

export const NotificationSend: React.FC = () => {
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const [sendNotification] = useSendNotificationMutation();
  const [isUserTarget, setIsUserTarget] = useState<boolean>(false);
  const [action, setAction] = useState<TNotificationAction>('none');
  const [merchantDisplayValue, setMerchantDisplayValue] = useState('');
  const [userDisplayValue, setUserDisplayValue] = useState('');
  const [selectedMerchant, setSelectedMerchant] = useState<TAutoCompleteItem>();
  const [selectedOffer, setSelectedOffer] = useState<TAutoCompleteItem>();
  const [selectedUser, setSelectedUser] = useState<TAutoCompleteItem>();
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const { data: packages } = useGetPackagesQuery();

  const onFinish = useCallback(
    (attributes: any) => {
      modal.confirm({
        title: 'Notification',
        content: 'Are you sure you want to send the notification?',
        okType: 'primary',
        onOk: () => {
          const { title, body, target, topic, action } = attributes;

          const notificationTarget =
            target === 'token' ? { token: selectedUser?.value } : { topic };

          const notificationData =
            action !== 'none'
              ? {
                  data: {
                    route: selectedOffer?.value
                      ? `/merchants/${selectedMerchant?.value}/offers/${selectedOffer.value}`
                      : `/merchants/${selectedMerchant?.value}`,
                  },
                }
              : {};

          sendNotification({
            title,
            body,
            ...notificationTarget,
            ...notificationData,
          });
        },
      });
    },
    [modal, selectedMerchant, selectedOffer, selectedUser, sendNotification]
  );

  const handleMerchantSelect = (value: string, option: TAutoCompleteItem) => {
    setMerchantDisplayValue(option.label);
    setSelectedMerchant(option);
  };

  const handleUserSelect = (value: string, option: TAutoCompleteItem) => {
    setUserDisplayValue(option.label);
    setSelectedUser(option);
  };

  const fetchMerchantSuggestions = async (value: string) => {
    if (value && value.length >= 3) {
      const response = await axiosApi.get(`/merchants/new?search=${value}`, {
        access_token: accessToken,
      });

      const { data } = response.data;

      const suggestions = data.map((merchant: IMerchant, _: number) => ({
        label: merchant.attributes.name,
        value: merchant.id,
        key: merchant.id,
      }));
      setMerchantOptions(suggestions);
    } else {
      setMerchantOptions([]);
    }
  };

  const fetchUserSuggestions = async (value: string) => {
    if (value && value.length >= 3) {
      const response = await axiosApi.get(
        `/users?search=${value}&filter[status]=true&filter[has_firebase_token]=true`,
        {
          access_token: accessToken,
        }
      );

      const { data } = response.data;

      const suggestions = data.map((user: IUser, _: number) => ({
        label: user.attributes.full_name,
        value: user.attributes.token?.token,
        key: user.id,
      }));

      setUserOptions(suggestions);
    } else {
      setUserOptions([]);
    }
  };

  const debouncedFetchMerchantSuggestions = useCallback(
    debounce(fetchMerchantSuggestions, 300),
    []
  );

  const debouncedFetchUserSuggestions = useCallback(
    debounce(fetchUserSuggestions, 300),
    []
  );

  const handleMerchantSearch = async (value: string) => {
    setMerchantDisplayValue(value);
    debouncedFetchMerchantSuggestions(value);
  };

  const handleUserSearch = async (value: string) => {
    setUserDisplayValue(value);
    debouncedFetchUserSuggestions(value);
  };

  const handleActionChange = useCallback(() => {
    setSelectedMerchant(undefined);
    setSelectedOffer(undefined);
    setMerchantDisplayValue('');
    setMerchantOptions([]);
  }, []);

  const resetForm = useCallback(() => {
    form.resetFields();

    setAction('none');

    handleActionChange();
  }, [form, handleActionChange]);

  return (
    <AppForm form={form} onFinish={onFinish}>
      <Row>
        <Col xs={{ offset: 0 }} sm={{ offset: 4 }}>
          <Title level={3}>SuperApp Notification</Title>
        </Col>
      </Row>
      <Form.Item label="Title" name="title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Body" name="body" rules={[{ required: true }]}>
        <Input.TextArea rows={6} style={{ resize: 'none' }} />
      </Form.Item>
      <Form.Item label="Action" name="action" rules={[{ required: true }]}>
        <Radio.Group
          onChange={({ target }) => {
            setAction(target.value);

            handleActionChange();
          }}
        >
          <Radio value="none">None</Radio>
          <Radio value="offer_screen">Offer Screen</Radio>
          <Radio value="merchant_screen">Merchant Screen</Radio>
        </Radio.Group>
      </Form.Item>
      {action !== 'none' && (
        <>
          <Form.Item label="Merchant">
            <Space direction="vertical" style={{ width: '100%' }}>
              <AutoComplete
                options={merchantOptions}
                onSelect={handleMerchantSelect}
                onSearch={handleMerchantSearch}
                value={merchantDisplayValue}
                onChange={setMerchantDisplayValue}
              >
                <Search placeholder="Search Merchants" enterButton />
              </AutoComplete>
              {selectedMerchant && <Tag>{selectedMerchant?.label}</Tag>}
            </Space>
          </Form.Item>
          {action === 'offer_screen' && selectedMerchant && (
            <Form.Item label="Offer">
              <Space direction="vertical" style={{ width: '100%' }}>
                <OfferSelect
                  merchantId={selectedMerchant?.value}
                  handleSelect={(option) => {
                    setSelectedOffer(option);
                  }}
                />
                {selectedOffer && <Tag>{selectedOffer?.label}</Tag>}
              </Space>
            </Form.Item>
          )}
        </>
      )}
      <Form.Item
        label="Target"
        name="target"
        rules={[{ required: true }]}
        initialValue={'topic'}
      >
        <Radio.Group
          onChange={({ target }) => {
            setIsUserTarget(target.value === 'token');
            setSelectedUser(undefined);
          }}
        >
          <Radio value="topic">Topic</Radio>
          <Radio value="token">User</Radio>
        </Radio.Group>
      </Form.Item>
      {!isUserTarget && (
        <Form.Item label="Topic" name="topic" rules={[{ required: true }]}>
          <Select
            showSearch
            options={[
              {
                label: 'All users',
                value: 'topic_all_users',
              },
              ...(packages?.map((pkg) => ({
                label: `${pkg.attributes.name} users`,
                value: `topic_${pkg.attributes.name
                  .toLowerCase()
                  .replace(/\-/g, '')
                  .replace(/\s+/g, '_')}`,
              })) || []),
              {
                label: 'Android users',
                value: 'topic_android',
                disabled: true,
              },
              {
                label: 'iOS users',
                value: 'topic_ios',
                disabled: true,
              },
            ]}
          />
        </Form.Item>
      )}
      {isUserTarget && (
        <Form.Item label="User" rules={[{ required: true }]} required>
          <Space direction="vertical" style={{ width: '100%' }}>
            <AutoComplete
              options={userOptions}
              onSelect={handleUserSelect}
              onSearch={handleUserSearch}
              value={userDisplayValue}
              onChange={setMerchantDisplayValue}
            >
              <Search placeholder="Search Users" enterButton />
            </AutoComplete>
            {selectedUser && <Tag>{selectedUser?.label}</Tag>}
          </Space>
        </Form.Item>
      )}
      <Row>
        <Col
          xs={{ offset: 0 }}
          sm={{ offset: 4 }}
          md={{ offset: 4 }}
          lg={{ offset: 4 }}
        >
          <Space>
            <Button type="default" htmlType="button" onClick={resetForm}>
              Reset
            </Button>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </Space>
        </Col>
      </Row>
    </AppForm>
  );
};
