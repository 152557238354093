import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from './enums';
import { UserList } from './views/list';

export const userRoutes: IAppRoute[] = [
  {
    path: '/users',
    component: UserList,
    roles: [UserRoleEnum.ADMIN],
  },
  {
    path: '/users/:userId',
    roles: [UserRoleEnum.ADMIN],
  },
];
