import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { BannerList } from './views/list';

export const bannerRoutes: IAppRoute[] = [
  {
    path: '/banners',
    component: BannerList,
    roles: [UserRoleEnum.ADMIN],
  },
];
