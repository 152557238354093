import dayjs from 'dayjs';
import { Dayjs } from '../../../utils/date';
import { IOccasionFormValues } from '../types';

export const formatPayload = (data: IOccasionFormValues) => {
  const { start_date, start_time, end_date, end_time, cover, ...rest } = data;

  const startTime = dayjs(start_time);
  const startDate = dayjs(start_date).hour(startTime.hour()).minute(startTime.minute()).second(0);

  const endTime = dayjs(end_time);
  const endDate = dayjs(end_date).hour(endTime.hour()).minute(endTime.minute()).second(0);

  return {
    ...rest,
    start_date: Dayjs.toUTC(startDate).format(),
    end_date: Dayjs.toUTC(endDate).format(),
    cover: cover[0]?.response?.filename || undefined,
  };
};
