import { TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';

import { ICategory } from '../types';

const categoryApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getCategory: build.query<ICategory, { categoryId: string }>({
      query: ({ categoryId }) => ({
        url: `/categories/${categoryId}`,
      }),
    }),
    getCategories: build.query<ICategory[], void>({
      query: () => ({
        url: '/categories',
      }),
      transformResponse: (response: TResponse<ICategory[]>) => response.data,
    }),
  }),
});

export const { useGetCategoryQuery, useGetCategoriesQuery } = categoryApi;
