import { Avatar, TableProps, Tag } from 'antd';
import { DataTable } from '../../../core';
import { useGetCategoriesQuery } from '../store/category.api';
import { ICategoryAttributes } from '../types';

export const CategoryList = () => {
  const { data: categories, isFetching } = useGetCategoriesQuery();

  const columns: TableProps<ICategoryAttributes>['columns'] = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      width: 100,
      align: 'center',
      render: (icon) => <Avatar src={icon} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      width: 100,
      render: (status) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
  ];

  return (
    <DataTable
      paginated={false}
      columns={columns}
      data={categories}
      isLoading={isFetching}
    />
  );
};
