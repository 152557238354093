import { App } from 'antd';
import { has, isEmpty, some } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IMerchantBranchAttributes } from '../../features/merchants/features/branches/types';
import { IMerchantLinkAttributes } from '../../features/merchants/features/links/types';
import { IMerchantAttributes } from '../../features/merchants/types';
import { useImportWizard } from './useImportWizard';

export const useImportValidation = () => {
  const { merchants, branches, links } = useImportWizard();
  const [validation, setValidation] = useState(false);
  const { message } = App.useApp();

  const checkMerchantsValidity = useCallback(
    (merchants: Partial<IMerchantAttributes>[]): boolean =>
      !some(
        merchants,
        (merchant) =>
          !has(merchant, 'categories') || isEmpty(merchant.categories)
      ),
    []
  );

  const checkValidity = useCallback(
    (
      records: Partial<IMerchantBranchAttributes | IMerchantLinkAttributes> &
        { merchant: string }[]
    ) =>
      !some(
        records,
        (record) => !has(record, 'merchant') || isEmpty(record.merchant)
      ),
    []
  );

  useEffect(() => {
    if (!merchants || !branches || !links) {
      return;
    }

    Promise.all([
      checkMerchantsValidity(merchants),
      checkValidity(branches),
      checkValidity(links),
    ]).then(([merchantsValid, branchesValid, linksValid]) => {
      if (!merchantsValid || !branchesValid || !linksValid) {
        setValidation(false);
        return;
      }

      setValidation(true);
    });
  }, [
    merchants,
    branches,
    links,
    checkMerchantsValidity,
    checkValidity,
    message,
  ]);

  return {
    validation,
  };
};
