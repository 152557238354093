import { Form, FormInstance, Input, InputRef } from 'antd';
import { useRef, useState } from 'react';

type IProps = {
  form: FormInstance<any>;
};

const CustomOTPInput: React.FC<IProps> = ({ form }) => {
  const [otpValue, setOTPValue] = useState<string[]>(['', '', '', '', '', '']);
  const inputsRef = useRef<(InputRef | null)[]>([]);

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    setOTPValue((prevOTPValue) => {
      const updatedOTPValue = [...prevOTPValue];
      updatedOTPValue[index] = newValue;
      return updatedOTPValue;
    });

    if (newValue.length === 1) {
      if (index < inputsRef.current.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const validateOTP = (_: any, value: string[]) => {
    const otpCode = otpValue?.join('');
    if (otpCode.length !== 6) {
      return Promise.reject(new Error('Please enter a 6-digit OTP.'));
    }
    form.setFieldsValue({ redeem_code: otpCode });
    return Promise.resolve();
  };

  return (
    <Form.Item
      label="Redemption Code"
      name="redeem_code"
      required
      rules={[{ validator: validateOTP }]}
      onReset={() => setOTPValue(['', '', '', '', '', ''])}
    >
      <div>
        {Array.from({ length: 6 }).map((_, index) => (
          <Input
            key={index}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={1}
            value={otpValue[index]}
            ref={(el) => (inputsRef.current[index] = el)}
            onInput={(e) =>
              handleInput(e as React.ChangeEvent<HTMLInputElement>, index)
            }
            onKeyDown={(e) => handleKeyDown(e, index)}
            style={{
              width: '35px',
              height: '35px',
              textAlign: 'center',
              marginRight: '10px',
            }}
          />
        ))}
      </div>
    </Form.Item>
  );
};

export default CustomOTPInput;
