import { useCallback } from 'react';
import { useDrawer } from '../../../core';
import { FormUtils } from '../../merchants/utils/FormUtils';
import { BannerForm } from '../components/BannerForm';
import { useUpdateBannerMutation } from '../store/banner.api';

export const EditBanner: React.FC = () => {
  const { resource } = useDrawer();
  const [editBanner, { isSuccess }] = useUpdateBannerMutation();

  const onFinish = useCallback(
    (attributes: Record<string, any>) => {
      const { cover, ...rest } = attributes;

      const payload = {
        id: resource?.id,
        ...rest,
        ...(cover && { cover: FormUtils().getFileName(cover) }),
      };

      editBanner(payload);
    },
    [editBanner, resource?.id]
  );

  return <BannerForm onFinish={onFinish} isSubmitted={isSuccess} />;
};
