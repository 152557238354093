import { TResponse } from '../../../../../common/types';
import { superAppApi } from '../../../../../core';
import { IMerchantAttributes } from '../../../types';
import { IMerchantBranchAttributes } from '../../branches/types';
import { IMerchantLinkAttributes } from '../../links/types';
import { IImportTask, IImportTaskAttributes } from '../types';

const importApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getTask: build.query<IImportTask, { id: string }>({
      query: ({ id }) => ({
        url: `/import/tasks/${id}`,
      }),
      transformResponse: (response: TResponse<IImportTask>) => response.data,
    }),
    getTasks: build.query<IImportTask[], void>({
      query: () => ({
        url: `/import/tasks`,
      }),
      transformResponse: (response: TResponse<IImportTask[]>) => response.data,
    }),
    createTask: build.mutation<IImportTask, { filename: string }>({
      query: ({ filename }) => ({
        url: '/import/tasks',
        method: 'POST',
        body: {
          merchants_file: filename,
        },
      }),
      transformResponse: (response: TResponse<IImportTask>) => response.data,
    }),
    updateTask: build.mutation<
      IImportTask,
      { taskId: string; data: Partial<IImportTaskAttributes> }
    >({
      query: ({ taskId, ...reset }) => ({
        url: `/import/tasks/${taskId}`,
        method: 'PATCH',
        body: {
          data: {
            type: 'ImportTask',
            attributes: reset.data,
          },
        },
      }),
      transformResponse: (response: TResponse<IImportTask>) => response.data,
    }),
    executeTask: build.mutation<
      void,
      {
        taskId: string;
        data: {
          merchants: Partial<IMerchantAttributes>[];
          branches: Partial<IMerchantBranchAttributes>[];
          links: Partial<IMerchantLinkAttributes>[];
        };
      }
    >({
      query: ({ taskId, data }) => ({
        url: `/import/tasks/${taskId}`,
        method: 'POST',
        body: {
          data,
        },
      }),
    }),
  }),
});

export const {
  useGetTaskQuery,
  useCreateTaskMutation,
  useGetTasksQuery,
  useUpdateTaskMutation,
  useExecuteTaskMutation,
} = importApi;
