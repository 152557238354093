import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type TWizardState = {
  taskId?: string;
  merchants?: any;
  branches?: any;
  links?: any;
};

const initialState: TWizardState = {};

const wizardSlice = createSlice({
  name: 'superAppImportWizard',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<string>) => {
      state.taskId = action.payload;
    },
    setMerchants: (state, action: PayloadAction<any>) => {
      state.merchants = action.payload;
    },
    setMerchantCategories: (
      state,
      action: PayloadAction<{
        merchantIndex: number;
        categories: Array<string>;
      }>
    ) => {
      state.merchants[action.payload.merchantIndex].categories =
        action.payload.categories;
    },
    setBranchMerchant: (state, action: PayloadAction<any>) => {
      state.branches[action.payload.branchIndex].merchant =
        action.payload.merchant;
    },
    setBranchDistrict: (state, action: PayloadAction<any>) => {
      state.branches[action.payload.branchIndex].district_id =
        action.payload.district_id;
    },
    setLinkMerchant: (state, action: PayloadAction<any>) => {
      state.links[action.payload.linkIndex].merchant = action.payload.merchant;
    },
    setBranches: (state, action: PayloadAction<any>) => {
      state.branches = action.payload;
    },
    setLinks: (state, action: PayloadAction<any>) => {
      state.links = action.payload;
    },
    resetWizard: (state) => {
      state.taskId = undefined;
      state.merchants = undefined;
      state.branches = undefined;
      state.links = undefined;
    },
  },
});

export const {
  setTaskId,
  setMerchants,
  setBranches,
  setLinks,
  resetWizard,
  setMerchantCategories,
  setBranchMerchant,
  setLinkMerchant,
  setBranchDistrict,
} = wizardSlice.actions;
export default wizardSlice.reducer;
