import { API_BASEURL } from '../../../common/constants';
import { useAuthUser } from '../../../core';

export const AppSetting = () => {
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  return (
    <a
      href={`${API_BASEURL}/merchants/export?token=${accessToken}`}
      target="_blank"
      rel="noreferrer"
    >
      Download Merchants Template
    </a>
  );
};
