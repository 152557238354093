import { superAppApi } from '../../core';
import { IAuth, TSignInArgs } from '../types';

const headers = {
  // override the default headers
  'Content-Type': 'application/json',
};

export const authAPi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    signIn: build.mutation<IAuth, TSignInArgs>({
      query: (data) => ({
        url: '/auth/sign-in',
        method: 'POST',
        body: data,
        headers,
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: data,
        headers,
      }),
    }),
  }),
});

export const { useSignInMutation, useResetPasswordMutation } = authAPi;
