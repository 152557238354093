import { TResponse } from '../../../../../common/types';
import { superAppApi } from '../../../../../core';

import { IMerchantLink, IMerchantLinkAttributes } from '../types';

const linkApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getLinks: build.query<IMerchantLink[], { merchantId: string }>({
      query: ({ merchantId }) => ({
        url: `/merchants/${merchantId}/links`,
      }),
      providesTags: ['Links'],
      transformResponse: (response: TResponse<IMerchantLink[]>) =>
        response.data,
    }),
    createLink: build.mutation({
      query: ({ merchantId, ...data }) => ({
        url: `/merchants/${merchantId}/links`,
        method: 'POST',
        body: {
          data: {
            type: 'MerchantLink',
            attributes: data,
          },
        },
      }),
      invalidatesTags: ['Links'],
    }),
    updateLink: build.mutation<
      void,
      Partial<IMerchantLinkAttributes & { merchantId: string; linkId: string }>
    >({
      query: ({ merchantId, linkId, ...data }) => ({
        url: `/merchants/${merchantId}/links/${linkId}`,
        method: 'PATCH',
        body: {
          data: {
            id: linkId,
            type: 'MerchantLink',
            attributes: data,
          },
        },
      }),
      invalidatesTags: ['Links'],
    }),
  }),
});

export const {
  useGetLinksQuery,
  useCreateLinkMutation,
  useUpdateLinkMutation,
} = linkApi;
