import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { Dashboard } from './views/dashboard';

export const dashboardRoutes: IAppRoute[] = [
  {
    path: '/',
    exact: true,
    roles: [UserRoleEnum.ADMIN, UserRoleEnum.MERCHANT, UserRoleEnum.BRANCH],
    component: Dashboard,
  },
];
