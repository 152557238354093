import { ImportOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { App, Button, Flex, Space, TableProps, Tag, Typography } from 'antd';
import { BsToggles } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlineLockReset } from 'react-icons/md';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { TActionList } from '../../../../../common/types';

import {
  ActionListButton,
  AppDrawer,
  axiosApi,
  DataTable,
  drawerResource,
  openDrawer,
  useAppDispatch,
  useAuthUser,
  useDrawer,
} from '../../../../../core';

import { PasswordModal } from '../../../components/password_modal';
import {
  useGetBranchesQuery,
  useUpdateBranchMutation,
} from '../store/branch.api';
import { IMerchantBranchAttributes } from '../types';
import { CreateBranch } from './create';
import { UpdateBranch } from './update';

const { Text } = Typography;

export type TBranchDrawer = 'BranchDrawer';

export const BranchList: React.FC = () => {
  const { merchantId = '' } = useParams<string>();
  const { data: branches, isLoading } = useGetBranchesQuery({ merchantId });
  const [updateBranch] = useUpdateBranchMutation();
  const dispatch = useAppDispatch();
  const { resource } = useDrawer();
  const { modal } = App.useApp();
  const { auth: { access_token } = {} } = useAuthUser();

  const actions: TActionList['items'] = [
    {
      label: 'Toggle Status',
      key: 'toggle_status',
      icon: <BsToggles />,
      action: (record) => {
        modal.confirm({
          title: 'Toggle branch status',
          content: 'Are you sure you want to toggle status?',
          okType: 'primary',
          onOk: () => {
            updateBranch({
              merchantId,
              id: record.key,
              status: !record.status,
            });
          },
        });
      },
    },
    {
      label: 'Reset Password',
      key: 'reset_password',
      icon: <MdOutlineLockReset />,
      action: (record) => {
        modal.confirm({
          title: 'Reset Password',
          content: 'Are you sure you want to reset password?',
          okType: 'primary',
          onOk: () => {
            axiosApi
              .post(
                `/merchants/${merchantId}/branches/${record.key}/reset-password`,
                {},
                {
                  access_token,
                }
              )
              .then(({ data: response }) => {
                modal.success({
                  width: 500,
                  title: 'Success',
                  content: (
                    <PasswordModal
                      username={response.data.username}
                      password={response.data.password}
                    />
                  ),
                });
              })
              .catch((err) => console.log(err));
          },
        });
      },
    },
    {
      label: 'Edit',
      key: 'edit',
      icon: <FaRegEdit />,
      action: async (record) => {
        const { key: id, ...reset } = record;

        dispatch(
          drawerResource({
            id,
            type: 'MerchantBranch',
            attributes: reset,
          })
        );
        dispatch(openDrawer('BranchDrawer'));
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <RiDeleteBin5Fill />,
      disabled: true,
      action: (record) => {},
    },
  ];

  const columns: TableProps<IMerchantBranchAttributes>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: boolean) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Working Hours',
      dataIndex: 'working_hours',
    },
    {
      title: 'City',
      dataIndex: 'district',
      render: (district) => {
        return <Text>{district?.city.name}</Text>;
      },
    },
    {
      title: 'District',
      dataIndex: 'district',
      render: (district) => <Text>{district?.name}</Text>,
    },
    {
      align: 'center',
      width: 110,
      render: (record) => <ActionListButton items={actions} record={record} />,
    },
  ];

  return (
    <>
      <AppDrawer
        title={resource ? 'Update Branch' : 'Add New Branch'}
        drawerId={'BranchDrawer'}
      >
        {resource ? <UpdateBranch /> : <CreateBranch />}
      </AppDrawer>
      <Space direction="vertical">
        <Flex justify="end" gap={16}>
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => dispatch(openDrawer('BranchDrawer'))}
            >
              <PlusSquareOutlined /> Add Branch
            </Button>
            <Button type="primary">
              <ImportOutlined /> Import Branches
            </Button>
          </Space>
        </Flex>
        <DataTable
          isLoading={isLoading}
          columns={columns}
          data={branches || []}
        />
      </Space>
    </>
  );
};
