import { Tag } from 'antd';
import { OccasionStatusEnum } from '../types';

type TProps = {
  status: OccasionStatusEnum;
};

export const OccasionStatusChip: React.FC<TProps> = ({ status }) => {
  let color = 'default';

  switch (status) {
    case OccasionStatusEnum.DRAFT:
      color = 'cyan';
      break;
    case OccasionStatusEnum.ACTIVE:
      color = 'green';
      break;
    case OccasionStatusEnum.CANCELLED:
      color = 'red';
      break;
    case OccasionStatusEnum.INACTIVE:
      color = 'orange';
      break;
    case OccasionStatusEnum.COMPLETED:
      color = 'green';
      break;
  }

  return (
    <Tag color={color}>
      {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
    </Tag>
  );
};
