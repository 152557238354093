import { PlusOutlined } from '@ant-design/icons';
import { Form, Space, Upload } from 'antd';
import { API_UPLOAD_BASEURL, TFileInfo } from '../../common';
import { useAuthUser } from '../hooks/useAuthUser';

type TProps = {
  name: string;
  label: string;
  onFileUploaded?: (info: TFileInfo) => void;
};

export const FileInput: React.FC<TProps> = ({
  name,
  label,
  onFileUploaded,
}) => {
  const { auth: { access_token: accessToken } = {} } = useAuthUser();

  return (
    <Form.Item
      label={label}
      name={name}
      valuePropName="fileList"
      getValueFromEvent={(e: TFileInfo) => {
        if (e.file.status !== 'done') {
          return;
        }

        return e.fileList;
      }}
      rules={[{ required: true }]}
    >
      <Upload
        action={API_UPLOAD_BASEURL}
        listType="picture-card"
        accept="image/*"
        onChange={(info) => {
          if (info.file.status !== 'done') {
            return;
          }

          onFileUploaded?.call(this, info);
        }}
        maxCount={1}
        headers={{
          Authorization: `Bearer ${accessToken}`,
        }}
      >
        <Space direction="vertical">
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </Space>
      </Upload>
    </Form.Item>
  );
};
