import { Form, FormInstance } from 'antd';
import { useEffect } from 'react';
import { TDrawers } from '../../common/types';
import { drawerSubmit } from '../store/drawer.slice';
import { useAppDispatch } from '../store/store';
import { useDrawer } from './useAppDrawer';

type TFormReturn = {
  form: FormInstance<any>;
};

type TProps = {
  drawerId: TDrawers;
};

export const useForm = ({ drawerId }: TProps): TFormReturn => {
  const { isSubmitting, drawerId: openedDrawer } = useDrawer();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isSubmitting || !form || openedDrawer !== drawerId) {
      return;
    }

    form.submit();
    dispatch(drawerSubmit(false));
  }, [dispatch, form, drawerId, isSubmitting, openedDrawer]);

  return {
    form,
  };
};
