import { TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';

import { ICity, IDistrict } from '../features/branches/types';

type TGetDistrictsArgs = {
  cityId: string;
};

type TGetAllDistrictsArgs = {
  filter?: { cityId?: string };
};

const cityApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getCities: build.query<ICity[], void>({
      query: () => ({
        url: '/cities',
      }),
      transformResponse: (response: TResponse<ICity[]>) => response.data,
    }),
    getDistricts: build.query<IDistrict[], TGetDistrictsArgs>({
      query: ({ cityId }) => ({
        url: `/cities/${cityId}/districts`,
      }),
      transformResponse: (response: TResponse<IDistrict[]>) => response.data,
    }),
    getAllDistricts: build.query<IDistrict[], TGetAllDistrictsArgs>({
      query: ({ filter }) => ({
        url: `/districts`,
        params: filter,
      }),
      transformResponse: (response: TResponse<IDistrict[]>) => response.data,
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetDistrictsQuery,
  useGetAllDistrictsQuery,
} = cityApi;
