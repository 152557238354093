import { Select } from 'antd';
import { TAutoCompleteItem } from '../../../common/types';
import { useGetOffersQuery } from '../../merchants/features/offers/store/offer.api';

type TProps = {
  merchantId: string;
  handleSelect: (option: TAutoCompleteItem) => void;
};

export const OfferSelect: React.FC<TProps> = (props) => {
  const { merchantId, handleSelect } = props;
  const { data: offers = [], isLoading } = useGetOffersQuery({ merchantId });

  return (
    <Select
      loading={isLoading}
      onSelect={(_, option) => handleSelect(option)}
      options={offers.map((offer) => ({
        label: offer.attributes.name,
        value: offer.id,
        key: offer.id,
      }))}
    />
  );
};
