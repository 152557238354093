export enum UserTypeEnum {
  CUSTOMER = 'customer',
  MERCHANT = 'merchant',
  BRANCH = 'branch',
  STAFF = 'staff',
  PROVIDER = 'provider',
}

export enum UserRoleEnum {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  MERCHANT = 'merchant',
  BRANCH = 'branch',
  FINANCE = 'finance',
  OPS = 'ops',
}
