import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { NotificationSend } from './views/send';

export const notificationRoutes: IAppRoute[] = [
  {
    path: '/notifications',
    component: NotificationSend,
    roles: [UserRoleEnum.ADMIN],
  },
];
