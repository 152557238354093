import { PlusOutlined } from '@ant-design/icons';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Switch,
  TreeSelect,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { API_UPLOAD_BASEURL } from '../../../common/constants';

import {
  AppForm,
  closeDrawer,
  useAppDispatch,
  useAuthUser,
  useDrawer,
  useForm,
} from '../../../core';
import { useGetCategoriesQuery } from '../../categories/store/category.api';
import { useGetMerchantQuery } from '../store/merchant.api';
import { FormUtils } from '../utils/FormUtils';

const { TextArea } = Input;

type TMerchantFormProps = {
  isSubmitted: boolean;
  onFinish: (attributes: Record<string, any>) => void;
};

export const MerchantForm: React.FC<TMerchantFormProps> = ({
  onFinish,
  isSubmitted,
}) => {
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  const { data: categories, isLoading: categoriesLoading } =
    useGetCategoriesQuery();
  const { resource } = useDrawer();
  const { form } = useForm({ drawerId: 'MerchantDrawer' });
  const dispatch = useAppDispatch();
  const [featured, setFeatured] = useState<boolean>(false);
  const { data: merchant, isLoading: merchantLoading } = useGetMerchantQuery({
    id: resource?.id,
  });

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    dispatch(closeDrawer());
  }, [dispatch, isSubmitted]);

  useEffect(() => {
    if (
      !resource ||
      resource.type !== 'Merchant' ||
      form.isFieldsValidating() ||
      categoriesLoading
    ) {
      return;
    }

    form.setFieldsValue({
      name: resource.attributes.name,
      about: resource.attributes.about,
      end_date:
        resource.attributes.end_date && dayjs(resource.attributes.end_date),
      featured_start_date:
        resource.attributes.featured_start_date &&
        dayjs(resource.attributes.featured_start_date),
      featured_end_date:
        resource.attributes.featured_end_date &&
        dayjs(resource.attributes.featured_end_date),
      types: merchant?.included
        ?.filter((item) => item.type === 'MerchantType')
        .map((item) => item.attributes.type),
      status: resource.attributes.status,
      is_featured: resource.attributes.is_featured,
      categories: merchant?.included
        ?.filter((item) => item.type === 'SubCategory')
        .map((category) => category.id),
      logo: resource.attributes.logo && [
        {
          uid: '-1',
          name: resource.attributes.logo,
          url: resource.attributes.logo,
          thumbnail: resource.attributes.logo,
        },
      ],
      cover: resource.attributes.cover && [
        {
          uid: '-1',
          name: resource.attributes.cover,
          url: resource.attributes.cover,
          thumbnail: resource.attributes.cover,
        },
      ],
    });

    setFeatured(resource.attributes.is_featured);
  }, [form, categoriesLoading, merchant, resource]);

  return (
    <>
      {categoriesLoading || merchantLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <AppForm
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
        >
          <Form.Item
            name={'name'}
            label="Name"
            required
            rules={[{ required: true }]}
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            name={'logo'}
            label="Logo"
            valuePropName="fileList"
            getValueFromEvent={FormUtils().normFile}
            required
            rules={[{ required: true }]}
          >
            <Upload
              action={API_UPLOAD_BASEURL}
              listType="picture-card"
              accept="image/*"
              maxCount={1}
              headers={{
                Authorization: `Bearer ${accessToken}`,
              }}
            >
              <button style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            </Upload>
          </Form.Item>
          <Form.Item
            name={'about'}
            label="About"
            required
            rules={[{ required: true }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name={'end_date'}
            label="End Date"
            required
            rules={[{ required: true }]}
          >
            <DatePicker format={'YYYY-MM-DD'} />
          </Form.Item>
          <Form.Item name={'types'} label="Service Type" required>
            <Checkbox.Group
              options={[
                {
                  label: 'In Store',
                  value: 'in_store',
                },
                {
                  label: 'Online',
                  value: 'online',
                },
                {
                  label: 'Social Media',
                  value: 'social_media',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name={'status'}
            label="Status"
            required
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="draft">Draft</Select.Option>
              <Select.Option value="pending">Pending</Select.Option>
              <Select.Option value="published">Published</Select.Option>
              <Select.Option value="archived">Archived</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={'categories'}
            label="Categories"
            required
            rules={[{ required: true }]}
          >
            <TreeSelect
              showSearch
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Please select"
              multiple
              treeCheckable
              allowClear
              maxTagCount={1}
              treeNodeFilterProp="title"
              value={['156554ab-b79f-40ab-8c1b-f08d80767029']}
              treeData={categories?.map((category) => ({
                title: category.attributes.name,
                value: category.id,
                selectable: false,
                children: category.attributes.subs.map((sub) => ({
                  title: sub.name,
                  value: sub.id,
                })),
              }))}
            />
          </Form.Item>
          <Form.Item
            name={'cover'}
            label="Cover"
            valuePropName="fileList"
            getValueFromEvent={FormUtils().normFile}
            rules={[{ required: !resource }]}
          >
            <Upload
              action={API_UPLOAD_BASEURL}
              listType="picture-card"
              accept="image/*"
              maxCount={1}
              headers={{
                Authorization: `Bearer ${accessToken}`,
              }}
            >
              <button style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            </Upload>
          </Form.Item>
          <Form.Item
            name={'is_featured'}
            label="Featured"
            valuePropName="checked"
            getValueFromEvent={(value) => value}
          >
            <Switch onChange={setFeatured} />
          </Form.Item>
          {featured && (
            <>
              <Form.Item
                name={'featured_start_date'}
                label="Start Date"
                required
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name={'featured_end_date'}
                label="End Date"
                required
                rules={[{ required: true }]}
              >
                <DatePicker />
              </Form.Item>
            </>
          )}
        </AppForm>
      )}
    </>
  );
};
