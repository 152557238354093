import axios from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { API_BASEURL } from '../../common/constants';
import { TResponse } from '../../common/types';
import { IUser } from '../../features/users/types';
import { axiosApi } from '../api';
import { authenticated, fetchUser, signOut } from '../store/auth.slice';
import { useAppDispatch } from '../store/store';
import { useAuthUser } from './useAuthUser';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const {
    isLoggedIn,
    auth: { refresh_token, access_token, expires_in } = {},
    user,
  } = useAuthUser();

  const obtainTokens = useCallback(async () => {
    try {
      const response = await axios.post(
        `${API_BASEURL}/auth/refresh-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refresh_token}`,
          },
        }
      );

      dispatch(authenticated(response.data));
    } catch (err) {
      dispatch(signOut());
    }
  }, [refresh_token, dispatch]);

  const obtainUser = useCallback(async () => {
    try {
      const response = (await axiosApi.get('/auth/user', {
        access_token,
      })) as { data: TResponse<IUser> };

      dispatch(fetchUser({ ...response.data.data, meta: response.data.meta }));
    } catch (err) {
      console.log(err);
    }
  }, [access_token, dispatch]);

  useEffect(() => {
    if (access_token) {
      return;
    }

    obtainTokens();
  }, [access_token, obtainTokens]);

  useEffect(() => {
    let refreshTimer: NodeJS.Timeout;

    if (expires_in) {
      refreshTimer = setTimeout(
        obtainTokens,
        +expires_in * 1000 - dayjs().valueOf()
      );
    }

    return () => {
      if (refreshTimer) {
        clearTimeout(refreshTimer);
      }
    };
  }, [expires_in, obtainTokens]);

  useEffect(() => {
    if (isLoggedIn && !user) {
      obtainUser();
    }
  }, [isLoggedIn, user, obtainUser]);

  return {
    isLoggedIn,
  };
};
