import { TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';
import { IBanner } from '../types';

const bannersApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getBanners: build.query<IBanner[], void>({
      query: () => ({
        url: '/banners',
      }),
      transformResponse: (response: TResponse<IBanner[]>) => response.data,
      providesTags: ['Banners'],
    }),
    createBanner: build.mutation({
      query: (data) => ({
        url: '/banners',
        method: 'POST',
        body: {
          data: {
            attributes: data,
          },
        },
      }),
      invalidatesTags: ['Banners'],
    }),
    updateBanner: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/banners/${id}`,
        method: 'PATCH',
        body: {
          data: {
            attributes: rest,
          },
        },
      }),
      invalidatesTags: ['Banners'],
    }),
  }),
});

export const {
  useGetBannersQuery,
  useUpdateBannerMutation,
  useCreateBannerMutation,
} = bannersApi;
