import { ImportOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { App, Button, Flex, Space, TableProps, Tag } from 'antd';
import { BsToggles } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { TActionList } from '../../../../../common/types';

import {
  ActionListButton,
  AppDrawer,
  DataTable,
  drawerResource,
  openDrawer,
  useAppDispatch,
  useDrawer,
} from '../../../../../core';

import { useGetLinksQuery, useUpdateLinkMutation } from '../store/link.api';
import { IMerchantLink } from '../types';
import { CreateLink } from './create';
import { UpdateLink } from './update';

export type TLinkDrawer = 'LinkDrawer';

export const LinkList: React.FC = () => {
  const { merchantId = '' } = useParams<string>();
  const { data: links, isFetching } = useGetLinksQuery({ merchantId });
  const dispatch = useAppDispatch();
  const { resource } = useDrawer();
  const { modal } = App.useApp();
  const [updateLink] = useUpdateLinkMutation();

  const actions: TActionList['items'] = [
    {
      label: 'Toggle Status',
      key: 'toggle_status',
      icon: <BsToggles />,
      action: (record) => {
        modal.confirm({
          title: 'Toggle link status',
          content: 'Are you sure you want to toggle status?',
          okType: 'primary',
          onOk: () => {
            updateLink({
              merchantId,
              linkId: record.key,
              status: !record.status,
            });
          },
        });
      },
    },
    {
      label: 'Edit',
      key: 'edit',
      icon: <FaRegEdit />,
      action: (record) => {
        const { key: id, ...reset } = record;

        dispatch(
          drawerResource<IMerchantLink>({
            id,
            type: 'MerchantLink',
            attributes: reset,
          })
        );
        dispatch(openDrawer('LinkDrawer'));
      },
    },
    {
      label: 'Delete',
      key: 'delete',
      disabled: true,
      icon: <RiDeleteBin5Fill />,
      action: (record) => {},
    },
  ];

  const columns: TableProps['columns'] = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: string) => (
        <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'data',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      align: 'center',
      render: (status: boolean) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      align: 'center',
      width: 110,
      render: (record) => <ActionListButton items={actions} record={record} />,
    },
  ];

  return (
    <>
      <AppDrawer
        title={resource ? 'Update Link' : 'Add New Link'}
        drawerId={'LinkDrawer'}
      >
        {resource ? <UpdateLink /> : <CreateLink />}
      </AppDrawer>
      <Space direction="vertical">
        <Flex justify="end" gap={16}>
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => dispatch(openDrawer('LinkDrawer'))}
            >
              <PlusSquareOutlined /> Add Link
            </Button>
            <Button type="primary">
              <ImportOutlined /> Import Links
            </Button>
          </Space>
        </Flex>
        <DataTable
          isLoading={isFetching}
          columns={columns}
          data={links || []}
        />
      </Space>
    </>
  );
};
