import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { ICategory, TCategoryPaths } from '../features/categories/types';
import {
  ICustomer,
  IPayment,
  ISubscription,
  TCustomerPaths,
} from '../features/customers/types';
import { TCustomerDrawer } from '../features/customers/views/list';
import { TDashboardPaths } from '../features/dashboard/types';
import { TBranchDrawer } from '../features/merchants/features/branches/views/list';
import { TLinkDrawer } from '../features/merchants/features/links/views/list';
import { TOfferDrawer } from '../features/merchants/features/offers/views/list';

import { FormInstance } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { IAuth } from '../auth/types';
import { IBanner, TBannerPathes } from '../features/banners/types';
import { TBannerDrawer } from '../features/banners/views/list';
import { IMerchantBranch } from '../features/merchants/features/branches/types';
import { TImportPaths } from '../features/merchants/features/import/types';
import { IMerchantLink } from '../features/merchants/features/links/types';
import { IOffer, IRedeem } from '../features/merchants/features/offers/types';
import { IMerchant, TMerchantPaths } from '../features/merchants/types';
import { TMerchantDrawer } from '../features/merchants/views/list';
import { TNotificationPathes } from '../features/notifications/types';
import {
  IOccasion,
  IOccasionAttributes,
  TEventPath,
} from '../features/occasions';
import { TRedeemPaths } from '../features/redeem/types';
import { TReportsPaths } from '../features/reports/types';
import { TSettingPaths } from '../features/settings/types';
import { IStaff, TStaffPaths } from '../features/staff/types';
import { UserRoleEnum } from '../features/users/enums';
import { IDevice, IUser, TUserPaths } from '../features/users/types';

export type UUID = string;

export type TAppPath =
  | ['*'] // Not found
  | TDashboardPaths
  | TCategoryPaths
  | TUserPaths
  | TMerchantPaths
  | TSettingPaths
  | TCustomerPaths
  | TStaffPaths
  | TImportPaths
  | TBannerPathes
  | TNotificationPathes
  | TReportsPaths
  | TEventPath
  | TRedeemPaths;

export const API_TAG_TYPES = ['Offers', 'Merchants', 'Customers', 'Users'];

export interface IResponseMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export type TResponse<D, M = IResponseMeta, L = void> = {
  data: D;
  meta: M;
  links: L;
  included?: {
    id: string;
    type: string;
    attributes: Record<string, any>;
  }[];
};

export interface IRelationship {
  id: UUID;
  type: string;
}

export interface IAttribute {
  key?: string;
}

export interface IEntity<T extends string> {
  id: UUID;
  type: T;
  relationships?: Record<string, IRelationship | IRelationship[]>;
}

export type TEntity =
  | ICustomer
  | IMerchant
  | ICategory
  | IMerchantBranch
  | IOffer
  | IUser
  | IStaff
  | ISubscription
  | IPayment
  | IRedeem
  | IBanner
  | IOccasion
  | IDevice
  | IMerchantLink;

export type TPaginationParams = {
  page?: number;
  limit?: number;
  search?: string;
};
export type TDrawers =
  | TBranchDrawer
  | TOfferDrawer
  | TLinkDrawer
  | TCustomerDrawer
  | TBannerDrawer
  | TMerchantDrawer;

export type TForms = TDrawers;

export type TFileUploadResponse = {
  error?: string;
  message?: string;
  statusCode?: number;
  filename?: string;
};

export type TFileInfo = UploadChangeParam<UploadFile<TFileUploadResponse>>;

export type TActionList<T = any> = {
  record: T;
  items: Array<
    ItemType & {
      action: (record: T) => void;
    }
  >;
};

declare module 'axios' {
  interface AxiosRequestConfig {
    access_token?: IAuth['access_token'];
  }
}

export type TAutoCompleteItem = {
  key: string;
  label: string;
  value: string;
};

export interface ICurrencySnapshot {
  amount: number;
  currency: {
    code: 'EGP';
    base: number;
    exponent: number;
  };
  scale: number;
}

type ModalFormResource = IOccasionAttributes;

export type ModalFormProps<T = ModalFormResource> = {
  form?: FormInstance<any>;
  onSuccess?: () => void;
  onLoading?: () => void;
  resource?: T;
};

export type TUserRole =
  | UserRoleEnum.ADMIN
  | UserRoleEnum.MERCHANT
  | UserRoleEnum.BRANCH
  | UserRoleEnum.CUSTOMER
  | UserRoleEnum.OPS
  | UserRoleEnum.FINANCE;
