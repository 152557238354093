import { TResponse } from '../../../common/types';
import { superAppApi } from '../../../core';
import { IPackage } from '../../merchants/types';

const packageApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getPackages: build.query<IPackage[], void>({
      query: () => ({
        url: '/packages',
      }),
      transformResponse: (result: TResponse<IPackage[]>) => result.data,
    }),
  }),
});

export const { useGetPackagesQuery } = packageApi;
