import { Form, FormInstance, Modal } from 'antd';
import React, { useCallback } from 'react';

type FormModalProps = {
  title: string;
  children: React.ReactNode;
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  resetResource: () => void;
};

export const FormModal: React.FC<FormModalProps> = ({ children, title, state, resetResource }) => {
  const [opened, setOpened] = state;
  const [isLoading, setIsLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onCancel = useCallback(() => {
    setOpened(false);
  }, []);

  const onAfterClose = useCallback(() => {
    form.resetFields();
    resetResource();
  }, []);

  const onSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const onSuccess = useCallback(() => {
    setOpened(false);
    setIsLoading(false);
  }, []);

  const onLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  return (
    <Modal
      title={title}
      open={opened}
      width={600}
      onCancel={onCancel}
      afterClose={onAfterClose}
      onOk={onSubmit}
      okText="Submit"
      closable={true}
      destroyOnClose={true}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
    >
      {React.isValidElement(children)
        ? React.cloneElement(children, {
            ...children.props,
            form: form as FormInstance<any>,
            onSuccess: onSuccess,
            onLoading: onLoading,
          })
        : children}
    </Modal>
  );
};
