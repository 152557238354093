import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { closeDrawer, useAppDispatch, useForm } from '../../../core';

type TProps = {
  isSubmitted: boolean;
  onFinish: (values: any) => void;
};

export const CustomerForm: React.FC<TProps> = ({ onFinish, isSubmitted }) => {
  const { form } = useForm({ drawerId: 'CustomerDrawer' });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    dispatch(closeDrawer());
  }, [isSubmitted, dispatch]);

  return (
    <Form
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      style={{ maxWidth: 600 }}
      onFinish={onFinish}
    >
      <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};
