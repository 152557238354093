import { App, Avatar, Flex, Input, Space, TableProps, Tag } from 'antd';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { MdOutlinePendingActions } from 'react-icons/md';
import { TActionList } from '../../../common';
import { ActionListButton, DataTable, useAppParams } from '../../../core';
import { ICustomerAttributes } from '../../customers/types';
import { PermissionsModal } from '../components/PermissionsModal';
import { useGetUsersQuery } from '../store/user.api';

const { Search } = Input;

export const UserList = () => {
  const { page, limit, search, setParams } = useAppParams();
  const { data: users, isFetching } = useGetUsersQuery({ page, limit, search });
  const { message } = App.useApp();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();

  const actions: TActionList['items'] = [
    {
      label: 'Update Permissions',
      key: 'permissions',
      icon: <MdOutlinePendingActions />,
      action: (record) => {
        setSelectedUserId(record.key);
        setOpen(true);
      },
    },
  ];

  const columns: TableProps['columns'] = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      width: 100,
      align: 'center',
      render: (avatar: string) => <Avatar src={avatar} />,
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => (
        <Tag color={status ? 'green' : 'red'}>
          {status ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: string) => {
        let color = 'default';

        switch (type) {
          case 'staff':
            color = 'geekblue';
            break;
          case 'customer':
            color = 'green';
            break;
          case 'merchant':
            color = 'volcano';
            break;
        }

        return (
          <Tag color={color}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      render: (_, record: ICustomerAttributes) => (
        <ActionListButton items={actions} record={record} />
      ),
      width: 110,
      align: 'center',
    },
  ];

  const onSearch = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        setParams({ page: `1`, limit: `${limit}` });
        return;
      }

      if (value.length < 2) {
        message.error('Please enter at least 2 characters');
        return;
      }

      setParams({ search: value, page: `1`, limit: `${limit}` });
    },
    [limit, message, setParams]
  );

  return (
    <>
      <PermissionsModal open={open} setOpen={setOpen} userId={selectedUserId} />
      <Space direction="vertical">
        <Flex justify="end" gap={16}>
          <Search
            style={{ width: 300 }}
            placeholder="Search users"
            onSearch={onSearch}
            allowClear
          />
        </Flex>
        <DataTable
          isLoading={isFetching}
          columns={columns}
          total={users?.meta.totalItems}
          data={users?.data || []}
        />
      </Space>
    </>
  );
};
