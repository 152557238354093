import { TResponse } from '../../../common';
import { superAppApi } from '../../../core';

import { IUserActivity } from '../types';

const dashboardApi = superAppApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getUserActivities: build.query<IUserActivity[], void>({
      query: () => ({
        url: '/dashboard/user-activities',
      }),
      transformResponse: (response: TResponse<IUserActivity[]>) =>
        response.data,
    }),
  }),
});

export const { useGetUserActivitiesQuery } = dashboardApi;
