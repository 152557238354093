import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FileUtils } from '../../../utils/FileUtils';
import { OfferForm } from '../components/OfferForm';
import { useCreateOfferMutation } from '../store/offer.api';

export const CreateOffer: React.FC = () => {
  const { merchantId } = useParams<string>();
  const [createOffer, { isSuccess }] = useCreateOfferMutation();

  const onFinish = useCallback(
    (values: any) => {
      const {
        branches,
        cover,
        offer_type,
        offer_value,
        max_amount,
        min_cart_amount,
        limited_redeems,
        limited_redeems_type,
        limited_redeems_count,
        featured_start_date,
        featured_end_date,
        flash_deal_start_date,
        flash_deal_end_date,
        start_date,
        end_date,
        ...rest
      } = values;

      if (!merchantId) {
        return;
      }

      const coverFileName = FileUtils().getFileName(cover) || '';

      createOffer({
        ...rest,
        featured_start_date: featured_start_date?.format('YYYY-MM-DD') || null,
        featured_end_date: featured_end_date?.format('YYYY-MM-DD') || null,
        flash_deal_start_date:
          flash_deal_start_date?.format('YYYY-MM-DD') || null,
        flash_deal_end_date: flash_deal_end_date?.format('YYYY-MM-DD') || null,
        start_date: start_date?.format('YYYY-MM-DD'),
        end_date: end_date?.format('YYYY-MM-DD'),
        branches,
        cover: coverFileName,
        merchantId,
        components: [
          {
            type: offer_type,
            value: offer_value,
            max_amount,
            min_cart_amount,
            ...(limited_redeems && {
              limited_redeems,
              limited_redeems_type,
              limited_redeems_count,
            }),
          },
        ],
      });
    },
    [createOffer, merchantId]
  );

  return <OfferForm isSubmitted={isSuccess} onFinish={onFinish} />;
};
