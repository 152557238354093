import { App } from 'antd';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { closeDrawer, useAppDispatch } from '../../../../../core';
import { BranchForm } from '../components/BranchForm';
import { useCreateBranchMutation } from '../store/branch.api';
import { IMerchantBranchAttributes } from '../types';

type TBranchFormProps = {
  values?: IMerchantBranchAttributes;
};

export const CreateBranch: React.FC<TBranchFormProps> = ({ values }) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const [
    createBranch,
    { isSuccess: createIsSuccess, error: createErrors, reset: createReset },
  ] = useCreateBranchMutation();
  const dispatch = useAppDispatch();
  const { notification } = App.useApp();

  const onFinish = useCallback(
    (attributes: any) => {
      createBranch({
        ...attributes,
        merchantId,
      });
    },
    [createBranch, merchantId]
  );

  useEffect(() => {
    if (!createIsSuccess) {
      return;
    }

    dispatch(closeDrawer());
    createReset();
  }, [dispatch, createReset, createIsSuccess]);

  useEffect(() => {
    if (!createErrors) {
      return;
    }

    notification.error({
      message: 'Error',
      description: 'Please validate the form and try again.',
    });
  }, [createErrors, notification]);

  return <BranchForm onFinish={onFinish} />;
};
