import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { omit } from 'lodash';
import { TActionList } from '../../common/types';

export const ActionListButton: React.FC<TActionList> = ({ items, record }) => {
  const accutalMenu = items?.map((item) =>
    omit(item, 'action')
  ) as MenuProps['items'];

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: accutalMenu,
        onClick: ({ key }) =>
          items?.find((item) => item?.key === key)?.action(record),
      }}
    >
      <Button type="primary" size="small">
        Actions <DownOutlined />
      </Button>
    </Dropdown>
  );
};
