import { useCallback } from 'react';
import { CustomerForm } from '../components/CustomerForm';
import { useUpdateCustomerMutation } from '../store/customers.api';

export const UpdateCustomer: React.FC = () => {
  const [updateCustomer, { isSuccess }] = useUpdateCustomerMutation();

  const onFinish = useCallback(
    (values: any) => {
      updateCustomer({
        ...values,
      });
    },
    [updateCustomer]
  );

  return <CustomerForm onFinish={onFinish} isSubmitted={isSuccess} />;
};
