import { useCallback } from 'react';
import { TFileInfo } from '../../../../../common/types';

import {
  setBranches,
  useAppDispatch,
  useImportWizard,
} from '../../../../../core';
import { FileUtils } from '../../../utils/FileUtils';
import { useUpdateTaskMutation } from '../store/import.api';
import { BranchesImportTable } from './BranchesImportTable';
import { UploadButton } from './UploadButton';

export const BranchStep: React.FC = () => {
  const { taskId, branches } = useImportWizard();

  const dispatch = useAppDispatch();
  const [updateTask] = useUpdateTaskMutation();

  const onFileUploaded = useCallback(
    (info: TFileInfo) => {
      if (FileUtils().isFileUploaded(info) || !taskId) {
        return;
      }

      const file = info?.fileList[0].originFileObj as File;
      const filename = FileUtils().getFileName(info.fileList);

      FileUtils().readExcelFile(file, (data) => {
        dispatch(
          setBranches(
            data.map((branch: any) => ({ ...branch, key: `${branch.name}` }))
          )
        );

        updateTask({ taskId, data: { branches_file: filename } });
      });
    },
    [dispatch, taskId, updateTask]
  );

  return (
    <>
      {branches && taskId ? (
        <BranchesImportTable taskId={taskId} branches={branches} />
      ) : (
        <UploadButton
          onFileUploaded={onFileUploaded}
          label="Select Branches File"
        />
      )}
    </>
  );
};
