import { IAppRoute } from '../../common/interfaces';
import { UserRoleEnum } from '../users/enums';
import { CustomerList } from './views/list';
import { CustomerView } from './views/view';

export const customerRoutes: IAppRoute[] = [
  {
    path: '/customers',
    component: CustomerList,
    roles: [UserRoleEnum.ADMIN],
  },
  {
    path: '/customers/:customerId',
    component: CustomerView,
    roles: [UserRoleEnum.ADMIN],
  },
];
