import { Layout } from 'antd';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { UserRoleEnum } from '../../features/users/enums';
import { ContentLayout } from '../../layout/content.layout';
import { FooterLayout } from '../../layout/footer.layout';
import { HeaderLayout } from '../../layout/header.layout';
import { SiderLayout } from '../../layout/sider.layout';
import { useAuth } from '../hooks/useAuth';
import { useAuthUser } from '../hooks/useAuthUser';
import { Loading } from './Loading';

export const AppMain: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const { user: { attributes: { roles = [] } = {} } = {} } = useAuthUser();
  const isBranch = useMemo(() => {
    return roles.find((role) => role.role === UserRoleEnum.BRANCH);
  }, [roles]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return !isLoggedIn ? (
    <Loading />
  ) : (
    <Layout hasSider>
      <SiderLayout />
      <Layout
        style={{
          minHeight: '100vh',
          marginLeft: isMobile ? 0 : 200,
        }}
      >
        <HeaderLayout />

        <ContentLayout />

        <FooterLayout />
      </Layout>
    </Layout>
  );
};
