import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TDrawers } from '../../common/types';

export interface IDrawerState {
  open: boolean;
  isSubmitting?: boolean;
  resource?: any;
  drawerId?: TDrawers;
}

const initialState: IDrawerState = {
  open: false,
  isSubmitting: false,
  resource: undefined,
  drawerId: undefined,
};

const drawerSlice = createSlice({
  name: 'superAppDrawer',
  initialState,
  reducers: {
    openDrawer(state, action: PayloadAction<TDrawers>) {
      state.open = true;
      state.drawerId = action.payload;
    },
    closeDrawer(state) {
      state.open = false;
      state.isSubmitting = false;
      state.resource = undefined;
      state.drawerId = undefined;
    },
    drawerSubmit(state, action: PayloadAction<boolean>) {
      state.isSubmitting = action.payload;
    },
    drawerResource<T = any>(state: IDrawerState, action: PayloadAction<T>) {
      state.resource = action.payload;
    },
  },
});

export const { openDrawer, drawerSubmit, closeDrawer, drawerResource } =
  drawerSlice.actions;
export default drawerSlice.reducer;
