import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { merge } from 'lodash';
import { IAuthState } from '../auth/types';
import { API_BASEURL } from '../common/constants';
import { API_TAG_TYPES } from '../common/types';

export const superAppApi = createApi({
  reducerPath: 'superAppApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASEURL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const state = getState() as { superAppAuth: IAuthState };
      const accessToken = state.superAppAuth.auth?.access_token;

      return new Headers({
        ...headers,
        ...(accessToken
          ? {
              Authorization: `Bearer ${accessToken}`,
            }
          : {}),
        'Content-Type': 'application/json',
        portal: 'admin-portal',
      });
    },
  }),
  tagTypes: API_TAG_TYPES,
  endpoints: () => ({}),
});

export const axiosApi = axios.create({
  baseURL: API_BASEURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    portal: 'admin-portal',
  },
});

axiosApi.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const { access_token } = config;
    const newConfig = { ...config };

    newConfig.headers = merge(newConfig?.headers, {
      Authorization: `Bearer ${access_token}`,
    });

    return newConfig;
  }
);
