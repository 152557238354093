import { Tag } from "antd";
import { RedeemPaymentMethodEnum } from "../enums";

export const RedeemPaymentMethod: React.FC<{method: string}> = ({method}) => {
    let color = 'geekblue';

    switch (method) {
        case RedeemPaymentMethodEnum.CASH:
            color = 'geekblue';
            break;
        case RedeemPaymentMethodEnum.ORANGE_CASH:
            color = 'orange';
            break;
    }

    return <Tag color={color}>{method.split('_').map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')}</Tag>;
};