import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select, Switch, Upload } from 'antd';
import { useEffect } from 'react';
import { API_UPLOAD_BASEURL } from '../../../common/constants';
import {
  AppForm,
  closeDrawer,
  useAppDispatch,
  useAuthUser,
  useDrawer,
  useForm,
} from '../../../core';
import { useGetMerchantsQuery } from '../../merchants/store/merchant.api';
import { FormUtils } from '../../merchants/utils/FormUtils';
import { IBannerAttributes } from '../types';

type IProps = {
  onFinish: (attributes: Record<string, any>) => void;
  isSubmitted: boolean;
};

export const BannerForm: React.FC<IProps> = ({ onFinish, isSubmitted }) => {
  const { resource } = useDrawer();
  const { form } = useForm({ drawerId: 'BannerDrawer' });
  const { auth: { access_token: accessToken } = {} } = useAuthUser();
  const dispatch = useAppDispatch();
  const { data: merchants, isLoading } = useGetMerchantsQuery({
    page: 1,
    limit: 1000,
  });

  useEffect(() => {
    if (!resource) {
      return;
    }

    const banner = { ...resource.attributes } as IBannerAttributes;

    form.setFieldsValue({
      title: banner.title,
      order: banner.order,
      status: banner.status,
      merchant_id: banner.merchant.id,
      cover: [
        {
          uid: '-1',
          name: banner.cover,
          url: banner.cover,
          thumbnail: banner.cover,
        },
      ],
    });
  }, [form, resource]);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    dispatch(closeDrawer());
  }, [dispatch, isSubmitted]);

  return (
    <AppForm form={form} onFinish={onFinish}>
      <Form.Item
        label="Title"
        name="title"
        required
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Order"
        name="order"
        required
        rules={[{ required: true }]}
      >
        <Select
          options={Array.from({ length: 20 }, (_, i) => ({
            label: `${i + 1}`,
            value: i + 1,
          }))}
        />
      </Form.Item>
      <Form.Item label="Status" name="status">
        <Switch />
      </Form.Item>
      <Form.Item
        name={'cover'}
        label="Cover"
        valuePropName="fileList"
        getValueFromEvent={FormUtils().normFile}
        required
        rules={[{ required: true }]}
      >
        <Upload
          action={API_UPLOAD_BASEURL}
          listType="picture-card"
          accept="image/*"
          maxCount={1}
          headers={{
            Authorization: `Bearer ${accessToken}`,
          }}
        >
          <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </button>
        </Upload>
      </Form.Item>
      <Form.Item label="Merchant" name="merchant_id">
        <Select
          loading={isLoading}
          disabled={isLoading}
          showSearch
          optionFilterProp="label"
          options={merchants?.data?.map((merchant) => ({
            label: merchant.attributes.name,
            value: merchant.id,
          }))}
        />
      </Form.Item>
    </AppForm>
  );
};
