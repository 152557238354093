import { CloseCircleFilled } from '@ant-design/icons';
import { App } from 'antd';
import { useCallback, useEffect } from 'react';
import { MerchantForm } from '../components/MerchantForm';
import { useCreateMerchantMutation } from '../store/merchant.api';
import { FormUtils } from '../utils/FormUtils';

export const CreateMerchant: React.FC = () => {
  const { notification } = App.useApp();

  const [createMerchant, { isSuccess, error }] = useCreateMerchantMutation();

  const onFinish = useCallback(
    (attributes: Record<string, any>) => {
      const {
        logo,
        cover,
        end_date,
        featured_start_date,
        featured_end_date,
        ...rest
      } = attributes;

      createMerchant({
        end_date: end_date?.format('YYYY-MM-DD'),
        ...rest,
        ...(featured_start_date && {
          featured_start_date: featured_start_date.format('YYYY-MM-DD'),
        }),
        ...(featured_end_date && {
          featured_end_date: featured_end_date.format('YYYY-MM-DD'),
        }),
        ...(logo && { logo: FormUtils().getFileName(logo) }),
        ...(cover && { cover: FormUtils().getFileName(cover) }),
      });
    },
    [createMerchant]
  );

  useEffect(() => {
    if (!error) {
      return;
    }

    const message = (error as Record<string, any>).data?.errors[0]?.message;

    if (!message) {
      return;
    }

    notification.info({
      message: `Error`,
      description: message,
      icon: <CloseCircleFilled style={{ color: 'red' }} />,
    });
  }, [error, notification]);

  return <MerchantForm onFinish={onFinish} isSubmitted={isSuccess} />;
};
