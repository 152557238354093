import { Card, Col, Divider, Row, Tabs } from 'antd';
import Lottie from 'lottie-react';
import { useParams } from 'react-router-dom';
import walletAnimation from '../../../assets/animations/z6CHYTnyIt.json';
import { AppStatistic } from '../../../core';
import { Devices } from '../components/Devices';
import { PaymentHistory } from '../components/PaymentHistory';
import { ProfileCard } from '../components/ProfileCard';
import { SpendingList } from '../components/SpendingList';
import {
  useGetCustomerQuery,
  useGetStatisticsQuery,
  useGetSubscriptionsQuery,
} from '../store/customers.api';

export const CustomerView: React.FC = () => {
  const { customerId = '' } = useParams<string>();
  const { data: customer, isLoading: customersLoading } = useGetCustomerQuery({
    id: customerId,
  });
  const { data: statistics, isLoading: statisticsLoading } =
    useGetStatisticsQuery({ id: customerId! });
  const { data: subscriptions, isLoading: subscriptionsLoading } =
    useGetSubscriptionsQuery({ id: customerId });

  const tabs = [
    {
      label: 'Spending',
      key: '1',
      children: <SpendingList />,
    },
    {
      label: 'Payment History',
      key: '2',
      children: <PaymentHistory />,
    },
    {
      label: 'Devices',
      key: '3',
      children: <Devices userId={customer?.attributes.user.id!} />,
    },
    {
      label: 'Notifications',
      key: '4',
      children: <div>All notifications</div>,
    },
  ];

  return (
    <>
      <Row gutter={16} align="middle">
        <Col span={12}>
          <ProfileCard
            customer={customer}
            subscriptions={subscriptions}
            isLoading={customersLoading && subscriptionsLoading}
          />
        </Col>
        <Col span={12}>
          <Card size="small">
            <Row gutter={16}>
              <Col span={9}>
                <AppStatistic
                  title="Total Spending"
                  value={statistics?.attributes.total_spending.amount}
                  isLoading={statisticsLoading}
                />
              </Col>
              <Col span={9}>
                <AppStatistic
                  title="Total Saving"
                  value={statistics?.attributes.total_saving.amount}
                  isLoading={statisticsLoading}
                />
              </Col>
              <Col span={6}>
                <Lottie animationData={walletAnimation} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Divider />
      <Tabs type="card" items={tabs} />
    </>
  );
};
