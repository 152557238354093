import { Form, Input, Switch, TreeSelect } from 'antd';
import { useEffect } from 'react';
import { AppForm, useDrawer, useForm } from '../../../../../core';
import {
  useGetAllDistrictsQuery,
  useGetCitiesQuery,
} from '../../../store/city.api';
import { FormUtils } from '../../../utils/FormUtils';
import { IMerchantBranchAttributes } from '../types';

const { TextArea } = Input;

type TBranchFormProps = {
  onFinish: (attributes: IMerchantBranchAttributes) => void;
};

export const BranchForm: React.FC<TBranchFormProps> = ({ onFinish }) => {
  const { data: cities } = useGetCitiesQuery();
  const { data: districts, isLoading } = useGetAllDistrictsQuery({});
  const { form } = useForm({ drawerId: 'BranchDrawer' });
  const { resource } = useDrawer();

  useEffect(() => {
    if (!resource || resource.type !== 'MerchantBranch' || isLoading) {
      return;
    }

    form.setFieldsValue({
      ...resource.attributes,
      city: resource.attributes.district?.city.id,
      district_id: resource.attributes.district?.id,
    });
  }, [form, isLoading, resource]);

  return (
    <AppForm form={form} onFinish={onFinish}>
      <Form.Item
        name={'district_id'}
        label="District"
        required
        rules={[{ required: true }]}
      >
        <TreeSelect
          showSearch
          treeNodeFilterProp="title"
          placeholder="Select District"
          allowClear
          treeData={cities?.map((city) => ({
            title: city.attributes.name,
            value: city.id,
            selectable: false,
            checkable: false,
            children: districts
              ?.filter(
                (district) =>
                  district.attributes.city.name === city.attributes.name
              )
              .map((district) => ({
                title: district.attributes.name,
                value: district.id,
              })),
          }))}
        />
      </Form.Item>
      <Form.Item
        name={'address'}
        label="Address"
        required
        rules={[{ required: true }]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item name={'phone'} label="Phone" normalize={FormUtils().normValue}>
        <Input placeholder="+20" />
      </Form.Item>
      <Form.Item
        name={'working_hours'}
        label="Working Hours"
        required
        rules={[{ required: true }]}
      >
        <Input placeholder="10:00 AM - 5:00 PM" />
      </Form.Item>
      <Form.Item
        name={'status'}
        label="Status"
        valuePropName="checked"
        initialValue={true}
      >
        <Switch />
      </Form.Item>
      <Form.Item name={'location'} label="Map Link">
        <Input />
      </Form.Item>
    </AppForm>
  );
};
